import { useEffect, useState } from "react";
import IconLogoService from "../Logo/IconLogoService.js";

const ServiceAccordion = (props) => {
	const accordionNames = [
		{
			slug: 'ENTITY_SET_UP',
			title: 'Entity&nbsp;set&nbsp;up',
			benefits: [
				'Register your business',
				'Set you up for tax',
				'Help you start a business account',
				'Register domain',
				'Set up company servers',
				'Choose company name',
			],
		},
		{
			slug: 'BRANDING_MARKETING',
			title: 'Branding&nbsp;/&nbsp;Marketing',
			benefits: [
				'Logo Design',
				'Vision Mission Values',
				'Brand Identity Guide',
				'Company Pitch Deck',
				'Branded Legal Documents',
				'Social Banners, Signatures, Templates',
				'Website Design & Development',
			],
		},
		{
			slug: 'TECHNOLOGY',
			title: 'Technology',
			benefits: [
				'Web3 Powered ATS & CRM',
				'Integrated Softphone',
				'Unlimited Bulk SMS & Calls',
				'Automated Outreach Campaigns',
				'Branded Candidate Profiles',
				'Verified Email & Phone Finder',
				'Analytics Dashboard',
				'A.I Candidate Matching & Ranking',
			],
		},
		{
			slug: 'RESOURCES_SUPPORT',
			title: 'Resources&nbsp;&&nbsp;Support',
			benefits: [
				'LinkedIn Recruiter License',
				'Seek Premium Ads',
				'Seek Talent Search',
				'Monthly Coaching & Strategy Calls',
				'Budgeting, Goal Setting, Cashflow',
				'Business Leadership Training',
				'Scaling your agency',
			],
		},
		{
			slug: 'ACCOUNTS_ADMIN',
			title: 'Accounts&nbsp;&&nbsp;Admin',
			benefits: [
				'Invoicing Placements',
				'Chasing Overdue Debts',
				'Payroll Financing & Work Health Cover',
				'Accounts Payable & Receivable',
				'Cashflow & Expense Management',
				'Reference Checking',
				'Tax Preparation & Bookkeeping',
			],
		}
	];

	const [stateService, setStateService] = useState(0);
	const [isClicked, setIsClicked] = useState("false");

	const handleClicked = (index) => {
		setIsClicked("true");
		setStateService(index);
		setTimeout(() => {
			setIsClicked("false");
		}, 1000)
	}

	useEffect(() => {
		if (isClicked === "false") {
			const autoPlay = setTimeout(() => {
				if (props.isActiveStateService) {
					if (stateService < 4) {
						setStateService(stateService + 1);
					}
					if (stateService === 4) {
						setStateService(0);
					}
				}
			}, 2500)

			return () => {
				clearTimeout(autoPlay);
			};
		}
	}, [props.isActiveStateService, stateService, isClicked])


	return (
		<>
			{/* dekstop dan tablet screen step */}
			<nav aria-label="Progress" className="hidden md:block">
				<div className="flex justify-center">
					<ol className="flex items-center mt-10">
						{accordionNames.map((item, index) => (
							<li className={`${index === 4 ? 'pr-0 lg:pr-0' : 'pr-34 lg:pr-44'} relative`} key={item.slug}>
								<p
									onClick={() => handleClicked(index)}
									className={`${stateService >= index ? "text-gray-900" : "text-gray-500"} ${index === 0 || index === 2 ? "-ml-10" : "-ml-17"} cursor-pointer focus:outline-none text-sm lg:text-base absolute -mt-10`}>
									<span dangerouslySetInnerHTML={{ __html: item.title }}></span>
								</p>
								<div className="absolute inset-0 flex items-center">
									<div className={`${index === 4 ? 'hidden' : ''} h-0.5 w-full bg-gray-200`}>
										{stateService === index &&
											<div className="bg-gray-900 w-full h-full step-width-animate"></div>
										}
										{stateService > index &&
											<div className="bg-gray-900 w-full h-full"></div>
										}
									</div>
								</div>
								<div
									onClick={() => handleClicked(index)}
									className={`${stateService >= index ? "bg-gray-900" : "bg-gray-300 border-2 border-gray-300"} cursor-pointer group relative w-3 h-3 flex items-center justify-center rounded-full`} />
							</li>
						))}
					</ol>
				</div>
				<div className="grid grid-cols-2 xl:px-6 mt-34 lg:mt-40 xl:mt-25">
					<div className="flex justify-center items-center">
						<div id="logo_animate_third_section" className="mt-4">
							<div className={`
									${stateService === 0 ? 'rotate-0' : ''}
									${stateService === 1 ? 'rotate-60' : ''}
									${stateService === 2 ? 'rotate-120' : ''}
									${stateService === 3 ? 'rotate-180' : ''}
									${stateService === 4 ? 'rotate-240' : ''}
									transform transition-transform duration-700 w-full lg:w-50 h-36 sm:h-72 flex justify-center`}>
								<IconLogoService />
							</div>
						</div>
					</div>
					<div className="flex justify-center">
						{/* Entity set up */}
						{stateService === 0 &&
							<span className="show-benefit-animate text-left ml-5 min-w-0 flex flex-col">
								<span className="text-3xl font-semibold text-gray-900 tracking-wide">Entity set up</span>
								<ul className="mt-4">
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Register your business
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Set you up for tax
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Help you start a business account
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Register domain
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Set up company servers
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Choose company name
										</span>
									</li>
								</ul>
							</span>
						}
						{/* Branding / Marketing */}
						{stateService === 1 &&
							<span className="show-benefit-animate text-left ml-5 min-w-0 flex flex-col">
								<span className="text-3xl font-semibold text-gray-900 tracking-wide">Branding / Marketing</span>
								<ul className="mt-4">
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Logo Design
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Vision Mission Values
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Brand Identity Guide
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Company Pitch Deck
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Branded Legal Documents
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Social Banners, Signatures, Templates
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Website Design & Development
										</span>
									</li>
								</ul>
							</span>
						}
						{/* Technology */}
						{stateService === 2 &&
							<span className="show-benefit-animate text-left ml-5 min-w-0 flex flex-col">
								<span className="text-3xl font-semibold text-gray-900 tracking-wide">Technology</span>
								<ul className="mt-4">
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Web3 Powered ATS & CRM
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Integrated Softphone
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Unlimited Bulk SMS & Calls
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Automated Outreach Campaigns
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Branded Candidate Profiles
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Verified Email & Phone Finder
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Analytics Dashboard
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											A.I Candidate Matching & Ranking
										</span>
									</li>
								</ul>
							</span>
						}
						{/* Resources & Support */}
						{stateService === 3 &&
							<span className="show-benefit-animate text-left ml-5 min-w-0 flex flex-col">
								<span className="text-3xl font-semibold text-gray-900 tracking-wide">Resources & Support</span>
								<ul className="mt-4">
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											LinkedIn Recruiter License
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Seek Premium Ads
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Seek Talent Search
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Monthly Coaching & Strategy Calls
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Budgeting, Goal Setting, Cashflow
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Business Leadership Training
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Scaling your agency
										</span>
									</li>
								</ul>
							</span>
						}
						{/* Accounts & Admin */}
						{stateService === 4 &&
							<span className="show-benefit-animate text-left ml-5 min-w-0 flex flex-col">
								<span className="text-3xl font-semibold text-gray-900 tracking-wide">Accounts & Admin</span>
								<ul className="mt-4">
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Invoicing Placements
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Chasing Overdue Debts
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Payroll Financing & Work Health Cover
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Accounts Payable & Receivable
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Cashflow & Expense Management
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Reference Checking
										</span>
									</li>
									<li className="flex items-center mt-4">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
										</svg>
										<span className="text-gray-900 text-2xl">
											Tax Preparation & Bookkeeping
										</span>
									</li>
								</ul>
							</span>
						}
					</div>
				</div>
			</nav>
			{/* mobile screen step */}
			<nav aria-label="Progress" className="md:hidden flex justify-center">
				<ol className="overflow-hidden">
					{accordionNames.map((item, index) => (
						<li className={`${index === 4 ? 'pb-4' : 'pb-6'} relative`} key={item.slug}>
							{index !== 4 &&
								<div className="-ml-px absolute mt-0.5 top-4 left-1.5 w-0.5 h-full bg-gray-200">
									{stateService === index &&
										<div className="bg-gray-900 w-full h-full step-height-animate"></div>
									}
									{stateService > index &&
										<div className="bg-gray-900 w-full h-full"></div>
									}
								</div>
							}
							<button type="button" className="relative flex items-start group focus:outline-none">
								<span className="h-9 flex items-center">
									<span
										onClick={() => handleClicked(index)}
										className={`${stateService >= index ? "bg-gray-900" : "bg-gray-300"} cursor-pointer relative z-10 w-3 h-3 flex items-center justify-center rounded-full`}></span>
								</span>
								<span className="text-left ml-5 min-w-0 flex flex-col">
									<span onClick={() => handleClicked(index)} className={`${stateService >= index ? "text-gray-900" : "text-gray-500"} cursor-pointer focus:outline-none text-lg xs:text-2xl font-semibold text-gray-900 tracking-wide`}
										dangerouslySetInnerHTML={{ __html: item.title }}></span>
									<ul>
										{stateService === index &&
											item.benefits.map((benefit) => (
												<li className="show-benefit-animate flex items-start mt-3" key={benefit}>
													<div>
														<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
															<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
														</svg>
													</div>
													<span className="text-gray-900 text-base font-cera-pro-regular font-normal">
														{benefit}
													</span>
												</li>
											))
										}
									</ul>
								</span>
							</button>
						</li>
					))}
				</ol>
			</nav>
		</>
	)
}

export default ServiceAccordion;