import { useRef } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import { Navigation } from "swiper";

import ImgBlake from "../../assets/images/people_1.jpg";
import ImgDeclan from "../../assets/images/people_2.png";

const PeolpeOurStorySlider = () => {
	const navigationPrevRef = useRef(null)
	const navigationNextRef = useRef(null)

	return (
		<>
			<Swiper
				centeredSlides={true}
				grabCursor={true}
				loop={true}
				modules={[Navigation]}
				navigation={{
					prevEl: navigationPrevRef.current,
					nextEl: navigationNextRef.current,
				}}
				onBeforeInit={(swiper) => {
					swiper.params.navigation.prevEl = navigationPrevRef.current;
					swiper.params.navigation.nextEl = navigationNextRef.current;
				}}
			>
				<SwiperSlide className="w-56 h-266">
					<div className='flex justify-center w-full'>
						<div className=''>
							<p className="text-center text-lg sm:text-3xl font-normal text-gray-900">
								Meet Blake?
							</p>
							<div className="my-6 sm:w-full w-56 h-266 aspect-w-1 aspect-h-1 overflow-hidden lg:aspect-none">
								<img src={ImgBlake} alt="img" className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
							</div>
							<p className="text-center text-base sm:text-lg font-normal text-gray-900">
								Follow Blake on
								<span className="ml-1 cursor-pointer focus:outline-none focus:ring-0 inline-flex underline" onClick={() => window.open("https://www.linkedin.com/in/blakethompson92/")}>
									LinkedIn.
								</span>
							</p>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide className="w-56 h-266">
					<div className='flex justify-center w-full'>
						<div className=''>
							<p className="text-center text-lg sm:text-3xl font-normal text-gray-900">
								Meet Declan?
							</p>
							<div className="my-6 sm:w-full w-56 h-266 aspect-w-1 aspect-h-1 overflow-hidden lg:aspect-none">
								<img src={ImgDeclan} alt="img" className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
							</div>
							<p className="text-center text-base sm:text-lg font-normal text-gray-900">
								Follow Declan on
								<span className="ml-1 cursor-pointer focus:outline-none focus:ring-0 inline-flex underline" onClick={() => window.open("https://www.linkedin.com/in/declan-kluver/")}>
									LinkedIn.
								</span>
							</p>
						</div>
					</div>
				</SwiperSlide>
				<div ref={navigationPrevRef} className="left-6 right-auto absolute top-1/2 flex justify-center items-center z-10 cursor-pointer">
					<svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
						<path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
					</svg>
				</div>
				<div ref={navigationNextRef} className="left-auto right-6 absolute top-1/2 flex justify-center items-center z-10 cursor-pointer">
					<svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
						<path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
					</svg>
				</div>
			</Swiper>
		</>
	)
}

export default PeolpeOurStorySlider;