import "../../assets/css/style.css"

const DotBackground = () => {
	return (
		<>
			{/* start */}
			<div className="w-full xl:flex px-4 sm:px-6 hidden">
				<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
				</div>
			</div>
			<div className="w-full flex xl:-mt-2.5">
				<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
				</div>
			</div>
			<div className="w-full flex px-4 sm:px-6 -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
				</div>
			</div>
			<div className="w-full flex -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
				</div>
			</div>
			<div className="w-full flex px-4 sm:px-6 -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
				</div>
			</div>
			{/* 5 end */}
			<div className="w-full flex -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
				</div>
			</div>
			<div className="w-full flex px-4 sm:px-6 -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
				</div>
			</div>
			<div className="w-full flex -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
				</div>
			</div>
			<div className="w-full flex px-4 sm:px-6 -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
				</div>
			</div>
			<div className="w-full flex -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
				</div>
			</div>
			{/* 10 end */}
			<div className="w-full flex px-4 sm:px-6 -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
				</div>
			</div>
			<div className="w-full flex -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
				</div>
			</div>
			<div className="w-full flex px-4 sm:px-6 -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
				</div>
			</div>
			<div className="w-full flex -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
				</div>
			</div>
			<div className="w-full flex px-4 sm:px-6 -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
				</div>
			</div>
			{/* 15 end */}
			<div className="w-full flex -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 sm:mt-1 sm:ml-1"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
				</div>
			</div>
			<div className="w-full flex px-4 sm:px-6 -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 sm:mt-1 sm:ml-1"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 sm:mt-1"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
				</div>
			</div>
			<div className="w-full flex -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 xl:opacity-90 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
				</div>
			</div>
			<div className="w-full flex px-4 sm:px-6 -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 xl:opacity-90 transform ring-0 transition ease-in-out duration-200 sm:mt-1 sm:ml-1"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
				</div>
			</div>
			<div className="w-full flex -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-90 md:opacity-100 xl:opacity-75 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 xl:opacity-90 transform ring-0 transition ease-in-out duration-200 sm:ml-1"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
				</div>
			</div>
			{/* 20 end */}
			<div className="w-full flex px-4 sm:px-6 -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-90 md:opacity-100 xl:opacity-75 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 xl:opacity-90 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
				</div>
			</div>
			<div className="w-full flex -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-75 md:opacity-100 xl:opacity-75 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-90 md:opacity-100 xl:opacity-75 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 xl:opacity-90 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
				</div>
			</div>
			<div className="w-full flex px-4 sm:px-6 -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-75 md:opacity-100 xl:opacity-75 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-90 md:opacity-100 xl:opacity-75 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 xl:opacity-90 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
				</div>
			</div>
			<div className="w-full flex -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-75 md:opacity-100 xl:opacity-75 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-75 md:opacity-100 xl:opacity-75 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-90 md:opacity-100 xl:opacity-75 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 xl:opacity-90 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
				</div>
			</div>
			<div className="w-full flex px-4 sm:px-6 -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-75 md:opacity-100 xl:opacity-75 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-75 md:opacity-100 xl:opacity-75 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-90 md:opacity-100 xl:opacity-75 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 xl:opacity-90 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
				</div>
			</div>
			{/* 25 end */}
			<div className="hidden w-full sm:flex xl:hidden -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
				</div>
			</div>
			<div className="hidden w-full sm:flex xl:hidden px-4 sm:px-6 -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
				</div>
			</div>
			<div className="hidden w-full sm:flex xl:hidden -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
				</div>
			</div>
			<div className="hidden w-full sm:flex xl:hidden px-4 sm:px-6 -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
				</div>
			</div>
			<div className="hidden w-full sm:flex xl:hidden -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 md:opacity-75 xl:opacity-100 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 md:opacity-90 xl:opacity-100 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
				</div>
			</div>
			{/* 30 end */}
			<div className="hidden w-full sm:flex xl:hidden px-4 sm:px-6 -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 md:opacity-75 xl:opacity-100 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 md:opacity-90 xl:opacity-100 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
				</div>
			</div>
			<div className="hidden w-full sm:flex xl:hidden -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 md:opacity-75 xl:opacity-100 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 md:opacity-75 xl:opacity-100 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 md:opacity-90 xl:opacity-100 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
				</div>
			</div>
			<div className="hidden w-full sm:flex xl:hidden px-4 sm:px-6 -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 md:opacity-75 xl:opacity-100 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 md:opacity-75 xl:opacity-100 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 md:opacity-90 xl:opacity-100 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
				</div>
			</div>
			<div className="hidden w-full sm:flex xl:hidden -mt-1 sm:-mt-2.5">
				<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 md:opacity-75 xl:opacity-100 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 md:opacity-75 xl:opacity-100 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 md:opacity-75 xl:opacity-100 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 opacity-100 md:opacity-90 xl:opacity-100 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four"></span>
					<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
					<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one"></span>
					<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two"></span>
					<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six"></span>
				</div>
			</div>
			{/* 34 end */}
		</>
	)
}

export default DotBackground;