const WhiteButton = (props) => {
	return (
		<>
			<button
				type="button"
				className={`inline-flex items-center justify-center px-3 sm:px-6 py-2 sm:py-2.5 border border-gray-300 text-base font-medium text-gray-800 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 ${props.customClass}`}
			>
				{props.title}
				<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2 transform rotate-45" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
					<path strokeLinecap="round" strokeLinejoin="round" d="M5 10l7-7m0 0l7 7m-7-7v18" />
				</svg>
			</button>
		</>
	)
}

export default WhiteButton;