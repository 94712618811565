import { useEffect, useState } from 'react';
import Slider from 'react-input-slider';

import ImgOrnament from '../../assets/images/ornament.svg';

const PricingSlider = () => {
	const [stateBill, setBillState] = useState({ x: 300000 });
	const [stateEarn, setEarnState] = useState(0);

	useEffect(() => {
		if (stateBill.x <= 500000) {
			setEarnState(((stateBill.x * 75) / 100));
		} else if (stateBill.x >= 500001 && stateBill.x <= 1000000) {
			setEarnState(((stateBill.x * 80) / 100));
		} else if (stateBill.x >= 1000001 && stateBill.x <= 2000000) {
			setEarnState(((stateBill.x * 85) / 100));
		} else if (stateBill.x >= 2000001) {
			setEarnState(((stateBill.x * 90) / 100));
		}
	}, [stateBill.x])

	return (
		<div className='border rounded-xl overflow-hidden bg-white'>
			<div className='mb-4 p-8 lg:px-30 lg:py-12 xl:p-12'>
				<div className="flex justify-center mb-6">
					<div className='text-center'>
						<p className="text-base sm:text-lg font-cera-pro-medium font-medium">Your billings of</p>
						<p className="text-2xl font-cera-pro-bold mt-2">${stateBill.x.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p>
					</div>
				</div>
				<div className='flex justify-center'>
					<Slider
					className="w-60 xs:w-64 lg:w-80"
						styles={{
							track: {
								backgroundColor: '#EAEDF0',
								width: '100%',
								height: 8
							},
							active: {
								backgroundColor: '#000000',
								height: 8
							},
							thumb: {
								width: 30,
								height: 30,
								backgroundColor: '#000000',
								border: 4,
								borderStyle: 'solid',
								borderColor: '#FFFFFF'
							},
						}}
						axis="x"
						x={stateBill.x}
						xmin={300000}
						xmax={2000000}
						onChange={({ x }) => setBillState(state => ({ ...state, x }))}
					/>
				</div>
			</div>
			<div className='w-full'>
				<div className="w-full border border-blue-400 rounded-b-xl">
					<div className='relative'>
						<div className='relative h-25 w-full md:h-40 sm:w-563 xl:w-480'>
							<img src={ImgOrnament} alt="" className='w-full' />
						</div>
						<div className='absolute top-0 inset-x-0 h-25 w-full md:h-40 sm:w-563 xl:w-480'>
							<div className="absolute inset-x-0 bottom-0 h-full"></div>
							<div className='relative h-full w-full flex items-center justify-center'>
								<div className='text-center'>
									<p className="text-lg sm:text-2xl font-cera-pro-bold">You could be earning</p>
									<div className="mt-2 text-3xl text-blue-600">
										<p className="font-cera-pro-bold">${parseInt(stateEarn.toFixed()).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PricingSlider;