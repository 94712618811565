import { useEffect } from "react";
import ImgAva1 from "../../assets/images/ava_1.png";
import ImgAva2 from "../../assets/images/ava_2.png";
import ImgAva3 from "../../assets/images/ava_3.png";
import ImgAva4 from "../../assets/images/ava_4.png";
import ImgAva5 from "../../assets/images/ava_5.png";
import ImgAva6 from "../../assets/images/ava_6.png";

const DotBackgroundHero = () => {

	useEffect(() => {
		setTimeout(() => {
			document.getElementById("setActiveDotsMoveAnimate").className = "dots-move-active";
		}, 5500);
	})

	return (
		<>
			<div id="setActiveDotsMoveAnimate">
				<div className="w-full xl:flex px-4 sm:px-6 hidden">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex xl:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				{/* 5 end */}
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				{/* 10 end */}
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-down-animation-four"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-down-animation-two"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-down-animation-three"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				{/* 15 end */}
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 sm:mt-1 sm:ml-1 dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 sm:mt-1 sm:ml-1 dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 sm:mt-1 dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 sm:mt-1 sm:ml-1 dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 sm:ml-1 dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				{/* 20 end */}
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				{/* 25 end */}
				<div className="w-full flex xl:hidden -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex xl:hidden px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex xl:hidden -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex xl:hidden px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="hidden w-full sm:flex xl:hidden -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				{/* 30 end */}
				<div className="hidden w-full sm:flex xl:hidden px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="hidden w-full sm:flex xl:hidden -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="hidden w-full sm:flex xl:hidden px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="hidden w-full sm:flex xl:hidden -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="hidden w-full sm:flex xl:hidden px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>

				{/* start */}
				<div className="w-full flex md:hidden -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex md:hidden px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex md:hidden -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex md:hidden px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex md:hidden xl:flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex md:hidden xl:flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex sm:hidden md:flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex sm:hidden md:flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				{/* 5 end */}
				<div className="w-full flex sm:hidden md:flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="z-auto xl:z-10 hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three">
							<img src={ImgAva6} alt="" className="hidden xl:block" id="desktop-ava-6" />
						</span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex sm:hidden md:flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="z-auto xl:z-10 inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200">
							<img src={ImgAva1} alt="" className="hidden xl:block" id="desktop-ava-1" />
						</span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex sm:hidden md:flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex sm:hidden md:flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="z-10 sm:z-auto inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four">
							<img src={ImgAva6} alt="" className="sm:hidden" id="mobile-ava-6" />
						</span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex sm:hidden md:flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="z-10 sm:z-auto inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one">
							<img src={ImgAva1} alt="" className="sm:hidden" id="mobile-ava-1" />
						</span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="z-10 sm:z-auto inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six">
							<img src={ImgAva5} alt="" className="sm:hidden" id="mobile-ava-5" />
						</span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				{/* 10 end */}
				<div className="w-full flex sm:hidden md:flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="z-auto xl:z-10 hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two">
							<img src={ImgAva5} alt="" className="hidden xl:block" id="desktop-ava-5" />
						</span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
						<span className="z-auto xl:z-10 inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six">
							<img src={ImgAva2} alt="" className="hidden xl:block" id="desktop-ava-2" />
						</span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="xl:z-auto lg:z-10 z-auto inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200">
							<img src={ImgAva1} alt="" className="hidden lg:block xl:hidden" id="tablet-ava-1" />
						</span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="lg:z-auto sm:z-10 z-auto hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five">
							<img src={ImgAva6} alt="" className="hidden sm:block lg:hidden" id="mini-tablet-ava-6" />
						</span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				{/* 15 end */}
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="z-10 sm:z-auto sm:-ml-0 inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two">
							<img src={ImgAva2} alt="" className="sm:hidden" id="mobile-ava-2" />
						</span>
						<span className="lg:z-auto sm:z-10 z-auto inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 sm:mt-1 sm:ml-1">
							<img src={ImgAva1} alt="" className="hidden sm:block lg:hidden" id="mini-tablet-ava-1" />
						</span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="z-10 sm:z-auto inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six">
							<img src={ImgAva4} alt="" className="sm:hidden" id="mobile-ava-4" />
						</span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="xl:z-auto lg:z-10 z-auto hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one">
							<img src={ImgAva6} alt="" className="hidden lg:block xl:hidden" id="tablet-ava-6" />
						</span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 sm:mt-1 sm:ml-1"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 sm:mt-1"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 sm:mt-1 sm:ml-1"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="z-auto xl:z-10 hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one">
							<img src={ImgAva4} alt="" className="hidden xl:block" id="desktop-ava-4" />
						</span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="z-auto xl:z-10 hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six">
							<img src={ImgAva3} alt="" className="hidden xl:block" id="desktop-ava-3" />
						</span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 sm:mt-1 sm:ml-1"></span>
						<span className="z-10 xl:z-auto lg:z-10 sm:z-auto inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one">
							<img src={ImgAva3} alt="" className="sm:hidden" id="mobile-ava-3" />
						</span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="lg:z-auto sm:z-10 z-auto hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six">
							<img src={ImgAva5} alt="" className="hidden sm:block lg:hidden" id="mini-tablet-ava-5" />
						</span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 sm:ml-1"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 sm:ml-1"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				{/* 20 end */}
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="xl:z-auto lg:z-10 z-auto hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six">
							<img src={ImgAva5} alt="" className="hidden lg:block xl:hidden" id="tablet-ava-5" />
						</span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="lg:z-auto sm:z-10 z-auto inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four">
							<img src={ImgAva2} alt="" className="hidden sm:block lg:hidden" id="mini-tablet-ava-2" />
						</span>
						<span className="z-10 xl:z-auto lg:z-10 sm:z-auto inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one">
							<img src={ImgAva2} alt="" className="hidden lg:block xl:hidden" id="tablet-ava-2" />
						</span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="lg:z-auto sm:z-10 z-auto hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five">
							<img src={ImgAva4} alt="" className="hidden sm:block lg:hidden" id="mini-tablet-ava-4" />
						</span>
						<span className="xl:z-auto lg:z-10 z-auto hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six">
							<img src={ImgAva4} alt="" className="hidden lg:block xl:hidden" id="tablet-ava-4" />
						</span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				{/* 25 end */}
				<div className="w-full flex -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="w-full flex px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="lg:z-auto sm:z-10 z-auto inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six">
							<img src={ImgAva3} alt="" className="hidden sm:block lg:hidden" id="mini-tablet-ava-3" />
						</span>
						<span className="xl:z-auto lg:z-10 z-auto hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two">
							<img src={ImgAva3} alt="" className="hidden lg:block xl:hidden" id="tablet-ava-3" />
						</span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="hidden w-full sm:flex xl:hidden -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="hidden w-full sm:flex xl:hidden px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="hidden w-full sm:flex xl:hidden -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				{/* 30 end */}
				<div className="hidden w-full sm:flex xl:hidden px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="hidden w-full sm:flex xl:hidden -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				<div className="hidden w-full sm:flex xl:hidden px-4 sm:px-6 -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-around flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
					</div>
				</div>
				<div className="hidden w-full sm:flex xl:hidden -mt-1.5 sm:-mt-2.5">
					<div className="relative inline-flex items-center justify-between flex-shrink-0 h-8 sm:h-12 w-full border-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-four dots-down-animation-four"></span>
						<span className="hidden sm:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
						<span className="hidden md:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-one dots-down-animation-one"></span>
						<span className="hidden lg:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-three dots-down-animation-three"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-five dots-down-animation-five"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-two dots-down-animation-two"></span>
						<span className="hidden xl:inline-block w-8.5 h-8.5 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 rounded-full border-2 border-gray-150 transform ring-0 transition ease-in-out duration-200 dots-move-animation-six dots-down-animation-six"></span>
					</div>
				</div>
				{/* 34 end */}
			</div>
		</>
	)
}

export default DotBackgroundHero;