import { Link } from "react-router-dom";

import PageNameConst from "../Const/PageNameConst.js";

import ImgLogo from "../assets/images/logo.png";
import IconYoutube from "../assets/icons/youtube.svg";
import IconLinkedin from "../assets/icons/linkedin.svg";
import IconInstagram from "../assets/icons/instagram.svg";
import IconTiktok from "../assets/icons/tiktok.svg";
import IconFacebook from "../assets/icons/facebook.svg";


const Footer = (props) => {
	return (
		<>
			<footer className="bg-white font-cera-pro-regular">
				<div className="max-w-7xl mx-auto px-6 sm:px-8 xl:px-4">
					<div className="font-cera-pro-medium sm:flex sm:items-start sm:justify-between my-11">
						<div className="mb-8 sm:mb-0">
							{props.isPage === PageNameConst.HOMEPAGE &&
								<div onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}>
									<img src={ImgLogo} alt="" className="h-8 cursor-pointer" />
									<p className="mt-3 text-sm text-gray-800 cursor-pointer font-cera-pro-regular font-normal">Powering the Next Generation of Recruiters</p>
								</div>
							}
							{props.isPage === PageNameConst.ABOUT_US &&
								<Link to="/">
									<img src={ImgLogo} alt="" className="h-8 cursor-pointer" />
									<p className="mt-3 text-sm text-gray-800 cursor-pointer font-cera-pro-regular font-normal">Powering the Next Generation of Recruiters</p>
								</Link>
							}
						</div>
						<div className="flex space-x-6">
							<button type="button" className="mb-0.5 focus:outline-none focus:ring-0" onClick={() => {
                window.open("https://www.tiktok.com/@xrecruiter")
                window.lintrk('track', { conversion_id: 10861828 });
              }}>
								<img src={IconTiktok} alt="linkedin" className="h-6" />
							</button>
							<button type="button" className="mb-0.5 focus:outline-none focus:ring-0" onClick={() => {
                window.open("https://www.youtube.com/channel/UC91PkrnrhBzaTmKHq-pEv6Q")
                window.lintrk('track', { conversion_id: 10861828 });
              }}>
								<img src={IconYoutube} alt="linkedin" className="h-6" />
							</button>
							<button type="button" className="mb-0.5 focus:outline-none focus:ring-0" onClick={() => {
                window.open("https://www.instagram.com/xrecruiter/")
                window.lintrk('track', { conversion_id: 10861828 });
              }}>
								<img src={IconInstagram} alt="linkedin" className="h-6" />
							</button>
							<button type="button" className="mb-0.5 focus:outline-none focus:ring-0" onClick={() => {
                window.open("https://www.facebook.com/xrecruiter")
                window.lintrk('track', { conversion_id: 10861828 });
              }}>
								<img src={IconFacebook} alt="linkedin" className="h-6" />
							</button>
							<button type="button" className="mb-0.5 focus:outline-none focus:ring-0" onClick={() => {
                window.open("https://www.linkedin.com/company/xrecruiter/")
                window.lintrk('track', { conversion_id: 10861828 });
              }}>
								<img src={IconLinkedin} alt="linkedin" className="h-6" />
							</button>
						</div>
					</div>
					<div className="border-t border-gray-200 pt-8 my-8 text-center">
						<p className="text-sm text-gray-500 mb-4 sm:mb-0">&copy; {new Date().getFullYear()}. All rights reserved.</p>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer;