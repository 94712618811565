import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import ImgLogo from "../assets/images/logo.png";

const Navbar = () => {
	const node = useRef();

	const [stateMobileMenu, toggleStateMobileMenu] = useState(false);

	const handleClickOutside = e => {
		if (node.current.contains(e.target)) {
			// inside click
			return;
		}
		// outside click
		toggleStateMobileMenu(false);
	};

	useEffect(() => {
		if (stateMobileMenu) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [stateMobileMenu]);

	return (
		<>
			<nav className="bg-transparent absolute w-full z-40" ref={node}>
				<div className="max-w-7xl mx-auto">
					<div className={`${stateMobileMenu ? 'bg-white' : ''} relative flex items-center justify-between h-20 sm:h-[82px] xl:h-24 px-4 sm:px-10 md:px-20 xl:px-4 xl:pt-4`}>
						<div className="absolute inset-y-0 right-0 pr-4 flex items-center sm:hidden">
							<button
								onClick={() => toggleStateMobileMenu(!stateMobileMenu)}
								type="button"
								className="inline-flex items-center justify-center p-2 rounded-md text-gray-800 focus:outline-none focus:ring-inset focus:ring-0" aria-controls="mobile-menu" aria-expanded="false"
							>
								<span className="sr-only">Open main menu</span>
								{stateMobileMenu
									?
									<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
										<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
									</svg>
									:
									<svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
										<path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
									</svg>
								}
							</button>
						</div>
						<div className="flex-1 flex items-center justify-start sm:items-stretch">
							<Link to="/">
								<div className="flex-shrink-0 flex items-center sm:pl-0 pl-2">
									<img src={ImgLogo} alt="" className="h-8" />
								</div>
							</Link>
						</div>
						<div className="hidden sm:flex absolute inset-y-0 right-0 items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
							<div className="sm:ml-6">
								<div className="flex space-x-4">
									<Link to="/" className="text-[#1E2124] hover:text-gray-900 px-3 py-2 mt-0.5 rounded-md text-sm font-cera-pro-regular">Home</Link>
									<Link to="/about" className="text-[#1E2124] hover:text-gray-900 px-3 py-2 mt-0.5 rounded-md text-sm font-cera-pro-regular">About Us</Link>
									<button onClick={() => window.open("https://open.spotify.com/show/4d7TiqHZ6ItKuWZkMCKsyO?si=5068817c3ecb49cd")} type="button" className="text-[#1E2124] hover:text-gray-900 px-3 py-2 -mt-0.5 rounded-md text-sm font-cera-pro-regular">Podcast</button>
									<button onClick={() => {
										window.open("https://calendly.com/declan-xr/45min")
										window.lintrk('track', { conversion_id: 10861828 });
									}} type="button" className="text-sm font-cera-pro-medium inline-flex items-center justify-center px-3 sm:px-6 py-2 sm:py-2.5 border border-gray-300 font-medium text-[#1E2124] bg-white hover:bg-gray-50 focus:outline-none focus:ring-0">
										Book a Call
										<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2 transform rotate-45" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 10l7-7m0 0l7 7m-7-7v18" />
										</svg>
									</button>
								</div>
							</div>
						</div>
					</div>

					{stateMobileMenu &&
						<div className="sm:hidden" id="mobile-menu">
							<div className={`${stateMobileMenu ? "opacity-1" : "opacity-0"} px-6 pb-6 flex flex-col bg-white shadow`}>
								<Link to="/" className="w-full text-[#1E2124] hover:text-gray-900 py-3 mb-4 rounded-md text-sm font-cera-pro-regular">Home</Link>
								<Link to="/about" className="w-full text-[#1E2124] hover:text-gray-900 py-3 mb-4 rounded-md text-sm font-cera-pro-regular">About Us</Link>
								<button onClick={() => window.open("https://open.spotify.com/show/4d7TiqHZ6ItKuWZkMCKsyO?si=5068817c3ecb49cd")} type="button" className="w-full text-[#1E2124] hover:text-gray-900 py-3 mb-4 rounded-md text-sm font-cera-pro-regular text-left">Podcast</button>
								<div className="w-full">
									<button onClick={() => {
										window.open("https://calendly.com/declan-xr/45min")
										window.lintrk('track', { conversion_id: 10861828 });
									}} type="button" className="w-full text-sm font-cera-pro-medium inline-flex items-center justify-center px-3 sm:px-6 py-3 sm:py-2.5 border border-gray-300 font-medium text-[#1E2124] bg-white hover:bg-gray-50 focus:outline-none focus:ring-0">
										Book a Call
										<svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2 transform rotate-45" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
											<path strokeLinecap="round" strokeLinejoin="round" d="M5 10l7-7m0 0l7 7m-7-7v18" />
										</svg>
									</button>
								</div>
							</div>
						</div>
					}
				</div>
			</nav >
		</>
	)
}

export default Navbar;