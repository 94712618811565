import { useEffect, useState } from 'react';

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import GradientSVG from "../Chart/GradientSVG.js";

const CircularProgressBar = (props) => {
	const idGradientCircleProgressBar = "id-gradient-circle-progress-bar";
	const [percentage, setPercentage] = useState(0);

	useEffect(() => {
		if (props.isActiveStateCircularProgressBar) {
			if (percentage.barValue < 100) {
				setTimeout(() => {
					setPercentage(previousState => {
						return {
							...previousState,
							barValue: previousState.barValue + 1.1,
						}
					});
				}, 10);
			}
			if (percentage.textValue < 90) {
				setTimeout(() => {
					setPercentage(previousState => {
						return {
							...previousState,
							textValue: previousState.textValue + 1
						}
					});
				}, 10);
			}
		} else {
			setPercentage(previousState => {
				return {
					...previousState,
					barValue: 0,
					textValue: 0
				}
			});
		}
	}, [props.isActiveStateCircularProgressBar, percentage.barValue, percentage.textValue]);

	return (
		<>
			<div className="w-46 xs:w-240 md:w-380">
				<div className='relative'>
					<div className='absolute z-10 flex justify-between w-full'>
						<div>
							<div className='px-2 sm:px-3.5 py-1 sm:py-1.5 font-cera-pro-regular font-normal text-xxxs sm:text-xs md:text-sm bg-white border rounded-l-lg rounded-tr-lg mt-10 md:mt-20 -ml-12 sm:-ml-24 md:-ml-40'>
								<p> Industry average <br />
									retained&nbsp;earnings&nbsp;<span className='font-cera-pro-medium font-medium'>(33%)</span></p>
							</div>
						</div>
						<div>
							<div className='px-2 sm:px-3.5 py-1 sm:py-1.5 font-cera-pro-regular font-normal text-xxxs sm:text-xs md:text-sm bg-white border rounded-r-lg rounded-tl-lg mt-44 md:mt-50 -mr-12 sm:-mr-24 md:-mr-42'>
								<p>Your Potential <br />
									retained&nbsp;earnings&nbsp;<span className='font-cera-pro-medium font-medium'>(90%)</span></p>
							</div>
						</div>
					</div>
				</div>
				<GradientSVG />
				<CircularProgressbarWithChildren value={percentage.barValue} circleRatio={0.70} strokeWidth={6.5}
					styles={
						buildStyles({
							pathTransition: "0s",
							rotation: 1 / 2 + 1 / 6.6,
							strokeLinecap: "butt",
							pathColor: `url(#${idGradientCircleProgressBar})`,
						})
					}
				>
					<div className="text-5xl md:text-7xl font-cera-pro-medium font-medium">
						{percentage.textValue}%
					</div>
					<div className="text-xxxs sm:text-xs md:text-sm font-cera-pro-regular font-normal mt-4">
						Your Potential retained earnings
					</div>
				</CircularProgressbarWithChildren>
			</div>
		</>
	)
}

export default CircularProgressBar;