import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Helmet from 'react-helmet'
import PageNameConst from "../Const/PageNameConst.js";

import BaseLayout from "../layouts/BaseLayout";

// Component
import DotBackgroundHero from "../components/Background/DotBackgroundHero.js";
import DotBackground from "../components/Background/DotBackground.js";
import WhiteButton from "../components/UI/Button/WhiteButton";
import ServiceAccordion from "../components/Accordion/ServiceAccordion.js";
import PricingSlider from "../components/Slider/PricingSlider.js";
import PoweredSlider from "../components/Slider/PoweredSlider.jsx";
import CircularProgressbar from '../components/Chart/CircularProgressBar';

// Image
import ImgLogo from "../assets/images/logo.png";

const Home = () => {
  const [activeStateCircularProgressBar, setActiveStateCircularProgressBar] = useState(false);
  const [activeStateService, setActiveStateService] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [showLogoTitle, setShowLogoTitle] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    // document.body.style.overflow = "hidden";
    // document.body.style.overflow = "scroll";
    setTimeout(() => {
      setShowLogo(true);
    }, 2000);
    setTimeout(() => {
      document.getElementById("bounceInAnimate").className = "bounceIn-animate";
    }, 3000);
    setTimeout(() => {
      setShowLogoTitle(true);
    }, 4000);
  })

  // on scroll
  useEffect(() => {
    window.onscroll = () => {
      if (document.documentElement.scrollTop > 1600) {
        document.getElementById("logo_animate_third_section").className = "block";
      } else if (document.documentElement.scrollTop < 800) {
        document.getElementById("logo_animate_third_section").className = "hidden";
      }

      if (document.documentElement.scrollTop > 1500) {
        setActiveStateService(true);
      }

      // DESKTOP
      if (document.documentElement.scrollTop > 580) {
        setActiveStateCircularProgressBar(true);
        document.getElementById("desktop-ava-1").className = "desktop-active-ava-animate";
        document.getElementById("desktop-ava-2").className = "desktop-active-ava-animate";
        document.getElementById("desktop-ava-3").className = "desktop-active-ava-animate";
        document.getElementById("desktop-ava-4").className = "desktop-active-ava-animate";
        document.getElementById("desktop-ava-5").className = "desktop-active-ava-animate";
        document.getElementById("desktop-ava-6").className = "desktop-active-ava-animate";
      } else if (document.documentElement.scrollTop < 250) {
        setActiveStateCircularProgressBar(false);
        document.getElementById("desktop-ava-1").className = "desktop-not-active-ava-animate";
        document.getElementById("desktop-ava-2").className = "desktop-not-active-ava-animate";
        document.getElementById("desktop-ava-3").className = "desktop-not-active-ava-animate";
        document.getElementById("desktop-ava-4").className = "desktop-not-active-ava-animate";
        document.getElementById("desktop-ava-5").className = "desktop-not-active-ava-animate";
        document.getElementById("desktop-ava-6").className = "desktop-not-active-ava-animate";
      }
      // TABLET
      if (document.documentElement.scrollTop > 1024) {
        document.getElementById("tablet-ava-1").className = "tablet-active-ava-animate";
        document.getElementById("tablet-ava-2").className = "tablet-active-ava-animate";
        document.getElementById("tablet-ava-3").className = "tablet-active-ava-animate";
        document.getElementById("tablet-ava-4").className = "tablet-active-ava-animate";
        document.getElementById("tablet-ava-5").className = "tablet-active-ava-animate";
        document.getElementById("tablet-ava-6").className = "tablet-active-ava-animate";
      } else if (document.documentElement.scrollTop < 502) {
        document.getElementById("tablet-ava-1").className = "tablet-not-active-ava-animate";
        document.getElementById("tablet-ava-2").className = "tablet-not-active-ava-animate";
        document.getElementById("tablet-ava-3").className = "tablet-not-active-ava-animate";
        document.getElementById("tablet-ava-4").className = "tablet-not-active-ava-animate";
        document.getElementById("tablet-ava-5").className = "tablet-not-active-ava-animate";
        document.getElementById("tablet-ava-6").className = "tablet-not-active-ava-animate";
      }
      // MINI TABLET
      if (document.documentElement.scrollTop > 912) {
        document.getElementById("mini-tablet-ava-1").className = "mini-tablet-active-ava-animate";
        document.getElementById("mini-tablet-ava-2").className = "mini-tablet-active-ava-animate";
        document.getElementById("mini-tablet-ava-3").className = "mini-tablet-active-ava-animate";
        document.getElementById("mini-tablet-ava-4").className = "mini-tablet-active-ava-animate";
        document.getElementById("mini-tablet-ava-5").className = "mini-tablet-active-ava-animate";
        document.getElementById("mini-tablet-ava-6").className = "mini-tablet-active-ava-animate";
      } else if (document.documentElement.scrollTop < 456) {
        document.getElementById("mini-tablet-ava-1").className = "mini-tablet-not-active-ava-animate";
        document.getElementById("mini-tablet-ava-2").className = "mini-tablet-not-active-ava-animate";
        document.getElementById("mini-tablet-ava-3").className = "mini-tablet-not-active-ava-animate";
        document.getElementById("mini-tablet-ava-4").className = "mini-tablet-not-active-ava-animate";
        document.getElementById("mini-tablet-ava-5").className = "mini-tablet-not-active-ava-animate";
        document.getElementById("mini-tablet-ava-6").className = "mini-tablet-not-active-ava-animate";
      }
      // MOBILE
      if (document.documentElement.scrollTop > 820) {
        document.getElementById("mobile-ava-1").className = "mobile-active-ava-animate";
        document.getElementById("mobile-ava-2").className = "mobile-active-ava-animate";
        document.getElementById("mobile-ava-3").className = "mobile-active-ava-animate";
        document.getElementById("mobile-ava-4").className = "mobile-active-ava-animate";
        document.getElementById("mobile-ava-5").className = "mobile-active-ava-animate";
        document.getElementById("mobile-ava-6").className = "mobile-active-ava-animate";
      } else if (document.documentElement.scrollTop < 350) {
        document.getElementById("mobile-ava-1").className = "mobile-not-active-ava-animate";
        document.getElementById("mobile-ava-2").className = "mobile-not-active-ava-animate";
        document.getElementById("mobile-ava-3").className = "mobile-not-active-ava-animate";
        document.getElementById("mobile-ava-4").className = "mobile-not-active-ava-animate";
        document.getElementById("mobile-ava-5").className = "mobile-not-active-ava-animate";
        document.getElementById("mobile-ava-6").className = "mobile-not-active-ava-animate";
      }
    }
  })

  return (
    <>
      <Helmet>
        <title>XRecruiter | Innovative Recruitment Software Australia</title>
        <meta name='description' content='XRecruiter supports recruiters running the recruitment agency, By offering innovative wholesale recruitment strategy solutions for all-size businesses.' />
        <meta name='keywords' content='recruitment software australia, recruitment agency, innovative recruitment' />
        <link rel='canonical' href='https://xrecruiter.io/' />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "EmploymentAgency",
            "name": "XRecruiter",
            "image": "https://xrecruiter.io/favicon.png",
            "@id": "",
            "url": "https://xrecruiter.io/",
            "telephone": "0420 814 172",
            "priceRange": "100",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "85 Commercial Rd",
              "addressLocality": "Teneriffe",
              "addressRegion": "QLD",
              "postalCode": "4005",
              "addressCountry": "AU"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": -27.454150,
              "longitude": 153.044890
            },
            "openingHoursSpecification": {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
              ],
              "opens": "08:30",
              "closes": "17:00"
            } 
          }`}
        </script>
      </Helmet>
      <BaseLayout isPage={PageNameConst.HOMEPAGE}>
        {/* hero section */}
        <section className="bg-gradient-to-tr from-pink-30 via-green-30 to-blue-30">
          <div className="relative">
            <div className="relative w-full h-1462 sm:h-2532 xl:h-1721 overflow-hidden mx-auto max-w-7xl">
              <div className="w-full h-full pt-20 pb-4 sm:pt-15 sm:pb-12 xl:py-6 px-3 xs:px-10 sm:px-12 xl:px-0">
                <div className="w-full h-full overflow-hidden">
                  {/* Dots Background */}
                  <DotBackgroundHero />
                </div>
              </div>
            </div>
            <div className="absolute top-0 inset-x-0 h-1462 sm:h-2532 xl:h-1721 p-4 overflow-hidden">
              <div className="absolute inset-x-0 bottom-0 h-full bg-gradient-to-tr from-pink-30 via-green-30 to-blue-30 opacity-5"></div>
              <div className="relative w-full flex items-center h-812 sm:h-1366 xl:h-815 -mt-2 lg:-mt-2 xl:mt-2">
                <div className="w-full relative md:flex md:justify-around lg:justify-center md:items-center">
                  {showLogo &&
                    <div className="relative w-full h-full flex items-center">
                      <div className="md:flex justify-center items-center w-full mt-2">
                        {/* Logo */}
                        <div id="bounceInAnimate" className="">
                          <div className="w-full md:w-50 h-36 sm:h-50 flex justify-center lg:mt-5 xl:mt-0 mb-10 md:mb-0">
                            <div className="z-10 absolute transform -rotate-60 h-36 sm:h-50">
                              <div className="logo-animate-transparent z-10 absolute h-36 sm:h-50 w-9 sm:w-13 flex flex-col justify-between">
                                <div className="button-logo-animate translate-x-0 pointer-events-none w-9 h-9 sm:w-13 sm:h-13 border-2 rounded-full border-purple-logo bg-purple-logo transform ring-0 transition ease-in-out duration-200"></div>
                                <div className="button-logo-animate translate-x-0 pointer-events-none w-9 h-9 sm:w-13 sm:h-13 border-2 rounded-full border-pink-logo bg-pink-logo transform ring-0 transition ease-in-out duration-200"></div>
                              </div>
                              <div className="logo-animate-gradient-purple-pink h-36 sm:h-50 w-9 sm:w-13 relative flex flex-col justify-between rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0"></div>
                            </div>
                            <div className="z-10 absolute">
                              <div className="transform rotate-0 relative flex flex-col justify-between h-36 sm:h-50 rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
                                <div className="single-button-logo-animate translate-x-0 pointer-events-none w-9 h-9 sm:w-13 sm:h-13 border-2 rounded-full border-blue-logo bg-blue-logo transform ring-0 transition ease-in-out duration-200"></div>
                                <div className="single-button-logo-animate translate-x-0 pointer-events-none w-9 h-9 sm:w-13 sm:h-13 border-2 rounded-full border-orange-logo bg-orange-logo transform ring-0 transition ease-in-out duration-200"></div>
                              </div>
                            </div>
                            <div className="z-10 absolute transform rotate-60 h-36 sm:h-50">
                              <div className="logo-animate-transparent z-10 absolute h-36 sm:h-50 w-9 sm:w-13 flex flex-col justify-between">
                                <div className="button-logo-animate translate-x-0 pointer-events-none w-9 h-9 sm:w-13 sm:h-13 border-2 rounded-full border-blue-logo-light bg-blue-logo-light transform ring-0 transition ease-in-out duration-200"></div>
                                <div className="button-logo-animate translate-x-0 pointer-events-none w-9 h-9 sm:w-13 sm:h-13 border-2 rounded-full border-green-logo bg-green-logo transform ring-0 transition ease-in-out duration-200"></div>
                              </div>
                              <div className="logo-animate-gradient-green-blue h-36 sm:h-50 w-9 sm:w-13 relative flex flex-col justify-between rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0"></div>
                            </div>
                          </div>
                        </div>
                        {showLogoTitle &&
                          <div className="logo-title-animation">
                            <h1 className="leading-10 sm:leading-72 md:leading-72 lg:leading-88 text-3xl sm:text-5xl md:text-6xl lg:text-7xl text-center font-cera-pro-bold sm:font-cera-pro-medium font-bold sm:font-medium text-gray-900">
                              Powering the <br /> Next Generation <br /> of Recruiters
                            </h1>
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="relative w-full h-812 sm:h-1366 xl:h-906">
                <div className="flex justify-center w-full h-full pt-11 sm:pt-25 md:pt-8 xl:pt-20">
                  <div className="px-0 sm:px-12 md:px-14 lg:px-16 xl:px-4">
                    <p className="leading-10 sm:leading-10 md:leading-72 lg:leading-72 text-2xl xs:text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-center font-cera-pro-bold sm:font-cera-pro-medium font-bold sm:font-medium text-gray-900">
                      Work for Yourself, <br className="sm:hidden" /> not By Yourself
                    </p>
                    <p className="text-gray-900 text-base sm:text-xl mt-8 text-center leading-6 lg:leading-8">
                      XRecruiter provides everything you need to feel confident to <br className="md:block hidden" />
                      launch and manage your own recruitment agency, regardless of experience.
                    </p>
                    <div className="mt-10 xs:mt-20 md:mt-36 xl:mt-16 flex justify-center">
                      <CircularProgressbar isActiveStateCircularProgressBar={activeStateCircularProgressBar} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* You Recruit, We Take Care of The Rest section */}
        <section className="bg-gradient-to-tr from-pink-30 via-green-30 to-blue-30 md:h-1366 xl:h-auto">
          <div className="mx-auto md:py-0 xl:py-30 py-12 max-w-7xl h-full xl:h-auto flex items-center">
            <div className="mx-auto md:-mt-9 xl:-mt-0">
              <div className="px-3 flex justify-center w-full mb-10 md:mb-18 xl:mb-12">
                <p className="leading-10 sm:leading-10 md:leading-72 lg:leading-72 text-2xl xs:text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-center font-cera-pro-bold sm:font-cera-pro-medium font-bold sm:font-medium text-gray-900">
                  You Recruit, <br className="xl:hidden md:block hidden" />
                  We Take Care of The Rest
                </p>
              </div>
              <div className="px-6 sm:px-12 md:px-14 lg:px-16 xl:px-4 ">
                <ServiceAccordion isActiveStateService={activeStateService} />
              </div>
            </div>
          </div>
        </section>
        {/* How much could you earn section */}
        <section className="bg-gradient-to-tr from-pink-30 via-green-30 to-blue-30">
          <div className="mx-auto px-5 sm:px-12 md:px-14 lg:px-16 xl:px-4 md:py-44 xl:py-30 py-12 max-w-7xl h-full flex items-center">
            <div className="mx-auto w-full">
              <div className="flex justify-center w-full mb-8 xl:mb-12">
                <p className="leading-10 sm:leading-10 md:leading-72 lg:leading-72 text-2xl xs:text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-center font-cera-pro-bold sm:font-cera-pro-medium font-bold sm:font-medium text-gray-900">
                  How much could you earn  <br className="hidden sm:block" />
                  with XRecruiter
                </p>
              </div>
              <div className="grid grid-cols-1 xl:px-8 mt-8 md:mt-20 xl:mt-20">
                <div className="flex justify-center items-center w-full">
                  <PricingSlider />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* For Recruiters, By Recruiters section */}
        <section className="bg-gradient-to-tr from-pink-30 via-green-30 to-blue-30">
          <div className="px-3 xs:px-5 sm:px-12 md:px-14 lg:px-16 xl:px-4 py-12 sm:py-32 lg:py-34 xl:py-30 mx-auto max-w-5xl">
            <div className="flex text-center px-10 sm:px-6">
              <h2 className="mx-auto text-2xl xs:text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-center font-cera-pro-bold sm:font-cera-pro-medium font-bold sm:font-medium text-gray-900">
                For Recruiters, <br className="sm:hidden" /> By Recruiters
              </h2>
            </div>
            <div className="mt-8 mx-auto w-full px-0 sm:mt-24 lg:px-4 xl:px-17 lg:mt-20">
              <div className="grid grid-cols-1 gap-4 sm:gap-6">
                <div className="p-6 md:p-10 bg-white">
                  <div className="flex sm:items-center items-start sm:justify-center justify-start">
                    <div className="mr-3.5 sm:mr-4 mt-2 sm:mt-0">
                      <div className="w-5 sm:w-7 h-5 sm:h-7 rounded-full border-2 border-purple-500 flex justify-center items-center">
                        <div className="w-2 sm:w-2.5 h-2 sm:h-2.5 rounded-full border-2 border-purple-500"></div>
                      </div>
                    </div>
                    <p className="text-gray-900 font-cera-pro-bold font-bold text-base xs:text-lg md:text-2xl leading-7">
                      Recruitment is about who <br className="hidden sm:hidden xs:inline-block" /> you know - not where you work.
                    </p>
                  </div>
                  <p className="mt-3 sm:mt-6 text-gray-900 font-cera-pro-regular font-normal text-base xs:text-lg">
                    Xrecruiter supports recruiters who typically wouldn't have considered starting their own recruitment agency
                    (due to fears or uncertainty) and provides them with all the same support and resources they are accustomed
                    to from their employer- however, instead of working for your employer-xrecruiter works for you!
                  </p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
                  <div className="p-6 sm:p-10 bg-white">
                    <p className="text-purple-500 font-cera-pro-bold font-bold text-base xs:text-lg sm:text-2xl">
                      Stage 1
                    </p>
                    <p className="mt-2 text-gray-900 font-cera-pro-regular font-normal text-base xs:text-lg">
                      Helping you set up your recruitment agency from scratch.
                    </p>
                  </div>
                  <div className="p-6 sm:p-10 bg-white">
                    <p className="text-purple-500 font-cera-pro-bold font-bold text-base xs:text-lg sm:text-2xl">
                      Stage 2
                    </p>
                    <p className="mt-2 text-gray-900 font-cera-pro-regular font-normal text-base xs:text-lg">
                      Supporting you in running your recruitment agency.
                    </p>
                  </div>
                </div>
                <div className="p-6 sm:p-10 bg-white">
                  <p className="text-gray-900 font-cera-pro-regular font-normal text-base xs:text-lg">
                    You see, as a high performing recruiter (billings in excess of $300,000) you are responsible for 90% of the
                    recruitment process such as finding clients, screening candidates, and making placements. The final 10% is
                    simply administration processes such as sending the placement invoice and paying resource costs-so why give
                    away the majority of your placement revenue for such simple tasks?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Powered By XRecruiter section */}
        <section className="bg-gradient-to-tr from-pink-30 via-green-30 to-blue-30">
          <div className="py-12 sm:py-48 md:py-64 xl:py-32">
            <div className="text-center px-4 mt-0 md:mt-12 xl:mt-0">
              <h2 className="mx-auto text-3xl sm:text-5xl md:text-6xl leading-10 tracking-wide sm:font-cera-pro-medium font-cera-pro-bold text-gray-900">
                Powered By XRecruiter
              </h2>
            </div>
            <div className="mt-8 w-full lg:mt-20 xl:mt-16 xl:pb-16 lg:pb-14">
              <div className="w-full">
                <PoweredSlider />
              </div>
            </div>
          </div>
        </section>
        {/* CTA section */}
        <section className="bg-gradient-to-tr from-pink-30 via-green-30 to-blue-30">
          <div className="relative">
            <div className="relative w-full h-380 sm:h-1366 xl:h-1024 overflow-hidden mx-auto max-w-7xl">
              <div className="w-full h-full py-12 sm:py-15 xl:py-6 px-8 sm:px-12 xl:px-0">
                <div className="w-full h-full overflow-hidden">
                  {/* Dots Background */}
                  <DotBackground />
                </div>
              </div>
            </div>
            <div className="absolute top-0 inset-x-0 h-380 sm:h-1366 xl:h-1024 flex items-end justify-end overflow-hidden">
              <div className="absolute inset-x-0 bottom-0 h-full bg-gradient-to-tr from-pink-30 via-transparent to-transparent opacity-90"></div>
              <div className="relative w-full h-full">
                <div className="xl:mt-72 xl:block flex justify-center items-center w-full h-full">
                  <div className="px-6">
                    <p className="leading-9 text-xl xs:text-2xl md:text-3xl md:leading-10 lg:text-40 lg:leading-56 text-center font-cera-pro-medium font-medium text-gray-900">
                      Australias best recruiters are partnering <br className="hidden sm:block" />
                      with XRecruiter and so should you.
                    </p>
                    <div className="w-full flex justify-center">
                      <div className="cursor-pointer focus:outline-none focus:ring-0 mt-8">
                        <Link to="/about">
                          <WhiteButton title="Learn More" customClass="sm:w-auto w-full text-base font-cera-pro-medium" />
                        </Link>
                      </div>
                    </div>
                    <div className="mt-10">
                      <div className="flex justify-center w-full">
                        <img src={ImgLogo} alt="" className="w-10/12 xs:w-auto mx-auto xs:h-14 md:h-20" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </BaseLayout>
    </>
  );
}

export default Home;
