import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import HoverVideoPlayer from 'react-hover-video-player';

import ImageBlended from "../../assets/images/BE.png";
import ImageHailSoft from "../../assets/images/HA.png";
import ImageVendito from "../../assets/images/VE.png";
import ImageIndustryTalent from "../../assets/images/IT.png";
import VideoVendito from "../../assets/videos/Vendito_Full_Animation.mp4";
import VideoBlended from "../../assets/videos/BE_Full_Animation.mp4";
import VideoHalisoft from "../../assets/videos/HR_FULL_ANIMATION.mp4";
import VideoIndustryTalent from "../../assets/videos/IndustryTalent.mp4";

const SupportedSlider = () => {
	const [readMoreBlended, setReadMoreBlended] = useState(false);
	const [readMoreHalisoft, setReadMoreHalisoft] = useState(false);
	const [readMoreVendito, setReadMoreVendito] = useState(false);
	const [readMoreIndustryTalent, setReadMoreIndustryTalent] = useState(false);

	return (
		<>
			<Swiper
				spaceBetween={50}
				slidesPerView={4}
				grabCursor={true}
				centeredSlides={true}
				loop={true}
				breakpoints={{
					0: {
						slidesPerView: 1.5,
						spaceBetween: 20,
					},
					430: {
						slidesPerView: 1.5,
						spaceBetween: 30,
					},
					512: {
						slidesPerView: 2,
						spaceBetween: 40,
					},
					768: {
						slidesPerView: 2.5,
						spaceBetween: 40,
					},
					1024: {
						slidesPerView: 2.76,
						spaceBetween: 48,
					},
					1180: {
						slidesPerView: 3.5,
						spaceBetween: 52,
					},
					1366: {
						slidesPerView: 3.8,
						spaceBetween: 52,
					},
				}}
			>
				<SwiperSlide className="">
					<div>
						<HoverVideoPlayer
							className="w-full"
							videoSrc={VideoIndustryTalent}
							pausedOverlay={
								<img
									src={ImageIndustryTalent}
									alt="industry-talent"
									style={{
										objectFit: 'cover',
									}}
									className="w-full"
								/>
							}
							muted={false}
							loadingOverlay={
								<div className="w-full h-full bg-gray-500 opacity-50 flex justify-center items-center">
									<svg
										role="status"
										className="z-10 opacity-100 mr-2 w-7 sm:w-14 h-7 sm:h-14 absolute animate-spin fill-white text-gray-800"
										viewBox="0 0 100 101"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
											fill="currentColor"
										/>
										<path
											d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
											fill="currentFill"
										/>
									</svg>
								</div>
							}
						/>
						<div className="flex justify-between items-center mt-8">
							<p className="text-base sm:text-lg md:text-2xl font-cera-pro-medium sm:font-cera-pro-regular text-gray-800 mb-2">
								Industry Talent
							</p>
							<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2 transform rotate-45" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
								<path strokeLinecap="round" strokeLinejoin="round" d="M5 10l7-7m0 0l7 7m-7-7v18" />
							</svg>
						</div>
						<p className="text-sm md:text-base font-normal text-gray-800 mb-2">
							Industry Talent is a Real Estate Recruitment Agency with a Corporate Casual Flair. Specializing in the NSW and
							{readMoreIndustryTalent && <span> QLD market, Industry talent absolutely knocks it out of the park with their branding, network and reputation.</span>}
							{!readMoreIndustryTalent && <span>...</span>}
							{!readMoreIndustryTalent && <span onClick={() => setReadMoreIndustryTalent(true)} className='cursor-pointer font-cera-pro-medium'>&nbsp;Read More</span>}
						</p>
						<button onClick={() => window.open("https://industrytalent.com.au/")} type="button" className="text-sm md:text-base font-normal text-gray-800 mb-2 underline">
							industrytalent.com.au
						</button>
					</div>
				</SwiperSlide>
				<SwiperSlide className="">
					<div>
						<HoverVideoPlayer
							className="w-full"
							videoSrc={VideoBlended}
							pausedOverlay={
								<img
									src={ImageBlended}
									alt=""
									style={{
										objectFit: 'cover',
									}}
									className="w-full"
								/>
							}
							muted={false}
							loadingOverlay={
								<div className="w-full h-full bg-gray-500 opacity-50 flex justify-center items-center">
									<svg
										role="status"
										className="z-10 opacity-100 mr-2 w-7 sm:w-14 h-7 sm:h-14 absolute animate-spin fill-white text-gray-800"
										viewBox="0 0 100 101"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
											fill="currentColor"
										/>
										<path
											d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
											fill="currentFill"
										/>
									</svg>
								</div>
							}
						/>
						<div className="flex justify-between items-center mt-8">
							<p className="text-base sm:text-lg md:text-2xl font-cera-pro-medium sm:font-cera-pro-regular text-gray-800 mb-2">
								Blended Employment
							</p>
							<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2 transform rotate-45" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
								<path strokeLinecap="round" strokeLinejoin="round" d="M5 10l7-7m0 0l7 7m-7-7v18" />
							</svg>
						</div>
						<p className="text-sm md:text-base font-normal text-gray-800 mb-2">
							Blended Employment specialize in recruiting sales positions across Australia.
							They are bold, innovative and have be
							{readMoreBlended && <span>en featured across many media outlets such as 7 news, Courier mail, business insider and much more!</span>}
							{!readMoreBlended && <span>...</span>}
							{!readMoreBlended && <span onClick={() => setReadMoreBlended(true)} className='cursor-pointer font-cera-pro-medium'>&nbsp;Read More</span>}
						</p>
						<button onClick={() => window.open("https://blendedemployment.com.au/")} type="button" className="text-sm md:text-base font-normal text-gray-800 mb-2 underline">
							blendedemployment.com.au
						</button>
					</div>
				</SwiperSlide>
				<SwiperSlide className="">
					<div>
						<HoverVideoPlayer
							className="w-full"
							videoSrc={VideoHalisoft}
							pausedOverlay={
								<img
									src={ImageHailSoft}
									alt=""
									style={{
										objectFit: 'cover',
									}}
									className="w-full"
								/>
							}
							muted={false}
							loadingOverlay={
								<div className="w-full h-full bg-gray-500 opacity-50 flex justify-center items-center">
									<svg
										role="status"
										className="z-10 opacity-100 mr-2 w-7 sm:w-14 h-7 sm:h-14 absolute animate-spin fill-white text-gray-800"
										viewBox="0 0 100 101"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
											fill="currentColor"
										/>
										<path
											d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
											fill="currentFill"
										/>
									</svg>
								</div>
							}
						/>
						<div className="flex justify-between items-center mt-8">
							<p className="text-base sm:text-lg md:text-2xl font-cera-pro-medium sm:font-cera-pro-regular text-gray-800 mb-2">
								Halisoft
							</p>
							<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2 transform rotate-45" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
								<path strokeLinecap="round" strokeLinejoin="round" d="M5 10l7-7m0 0l7 7m-7-7v18" />
							</svg>
						</div>
						<p className="text-sm md:text-base font-normal text-gray-800 mb-2">
							Halisoft are an accounting and finance recruitment agency that recruits positions nationally.
							Halisoft have
							{readMoreHalisoft && <span> a sharp, corporate and approachable feel with some amazing colour pallets. The brand is approachable, exceptionally well presented and very easy on the eye!</span>}
							{!readMoreHalisoft && <span>...</span>}
							{!readMoreHalisoft && <span onClick={() => setReadMoreHalisoft(true)} className='cursor-pointer font-cera-pro-medium'>&nbsp;Read More</span>}
						</p>
						<button onClick={() => window.open("https://halisoft.com/")} type="button" className="text-sm md:text-base font-normal text-gray-800 mb-2 underline">
							halisoft.com
						</button>
					</div>
				</SwiperSlide>
				<SwiperSlide className="">
					<div>
						<HoverVideoPlayer
							className="w-full"
							videoSrc={VideoVendito}
							pausedOverlay={
								<img
									src={ImageVendito}
									alt=""
									style={{
										objectFit: 'cover',
									}}
									className="w-full"
								/>
							}
							muted={false}
							loadingOverlay={
								<div className="w-full h-full bg-gray-500 opacity-50 flex justify-center items-center">
									<svg
										role="status"
										className="z-10 opacity-100 mr-2 w-7 sm:w-14 h-7 sm:h-14 absolute animate-spin fill-white text-gray-800"
										viewBox="0 0 100 101"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
											fill="currentColor"
										/>
										<path
											d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
											fill="currentFill"
										/>
									</svg>
								</div>
							}
						/>
						<div className="flex justify-between items-center mt-8">
							<p className="text-base sm:text-lg md:text-2xl font-cera-pro-medium sm:font-cera-pro-regular text-gray-800 mb-2">
								Vendito
							</p>
							<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2 transform rotate-45" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
								<path strokeLinecap="round" strokeLinejoin="round" d="M5 10l7-7m0 0l7 7m-7-7v18" />
							</svg>
						</div>
						<p className="text-sm md:text-base font-normal text-gray-800 mb-2">
							Vendito consulting are based in Brisbane and recruit heavily in sales, construction,
							transport, and healthcare.
							{readMoreVendito && <span> They have chosen to bring recruitment into the 21st century with a clean, energetic and fun brand that candidates and clients love!</span>}
							{!readMoreVendito && <span>...</span>}
							{!readMoreVendito && <span onClick={() => setReadMoreVendito(true)} className='cursor-pointer font-cera-pro-medium'>&nbsp;Read More</span>}
						</p>
						<button onClick={() => window.open("https://venditoconsulting.com.au/")} type="button" className="text-sm md:text-base font-normal text-gray-800 mb-2 underline">
							venditoconsulting.com.au
						</button>
					</div>
				</SwiperSlide>
			</Swiper>
		</>
	)
}

export default SupportedSlider;