import React from "react";

// hover video player
import HoverVideoPlayer from 'react-hover-video-player';

const PartnersSayWithHover = ({
  containerClassName, placeholderSrc, videoSrc
}) => {
  return (
    <div className={
      `
        w-full
        ${containerClassName}
        relative max-w-[335px] sm:max-w-[564px] md:max-w-[624px] lg:max-w-[864px] xl:max-w-[528px]
      `
    }>
      <div className={
        `
        relative
        h-173 sm:h-[317px] md:h-[351px] lg:h-[458px] xl:h-[280px]
        overflow-hidden
        `
      }>
        <HoverVideoPlayer
          className={`w-full h-full`}
          videoSrc={videoSrc}
          pausedOverlay={
            <div className="relative w-full h-full">
              <img
                src={placeholderSrc}
                alt=""
                style={{
                  objectFit: 'cover',
                }}
                className="relative w-full h-full"
              />

              <button 
                className="absolute w-8 h-8 md:w-15 md:h-15 xl:w-12 xl:h-12 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
              >
                <svg className="w-full h-full" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_b_35677_5421)">
                    <circle cx="40" cy="40" r="40" fill="black" fill-opacity="0.3"/>
                  </g>
                  <path d="M30.6641 26.6687V53.5068C30.6641 55.5533 32.6941 56.7968 34.2574 55.6829L53.2507 42.2638C53.5833 42.0302 53.8572 41.7069 54.047 41.3242C54.2368 40.9415 54.3363 40.5117 54.3363 40.0748C54.3363 39.6379 54.2368 39.2081 54.047 38.8254C53.8572 38.4426 53.5833 38.1194 53.2507 37.8858L34.2574 24.4926C33.9055 24.2399 33.4986 24.0973 33.0798 24.0799C32.6609 24.0625 32.2456 24.171 31.8776 24.3938C31.5097 24.6166 31.2027 24.9456 30.9891 25.346C30.7755 25.7464 30.6632 26.2033 30.6641 26.6687Z" fill="white"/>
                  <defs>
                    <filter id="filter0_b_35677_5421" x="-8" y="-8" width="96" height="96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="4"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_35677_5421"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_35677_5421" result="shape"/>
                  </filter>
                  </defs>
                </svg>
              </button>
            </div>
          }
          muted={false}
          loadingOverlay={
            <div className="w-full h-full bg-gray-500 opacity-50 flex justify-center items-center">
              <svg
                role="status"
                className="z-10 opacity-100 mr-2 w-7 sm:w-14 h-7 sm:h-14 absolute animate-spin fill-white text-gray-800"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          }
        />
        {/* <img src={ImgPartner} alt="How Does XRecruiter Work" className="w-full h-full" /> */}
      </div>
    </div>
  )
}

export default PartnersSayWithHover