const IconLogoService = () => {
	return (
		<>
			<div className="z-10 absolute transform -rotate-60 h-36 sm:h-72">
				<div className="logo-animate-transparent z-10 absolute h-36 sm:h-72 w-9 sm:w-17 flex flex-col justify-between">
					<div className="button-logo-animate translate-x-0 pointer-events-none w-9 h-9 sm:w-17 sm:h-17 border-2 rounded-full border-purple-logo bg-purple-logo transform ring-0 transition ease-in-out duration-200"></div>
					<div className="button-logo-animate translate-x-0 pointer-events-none w-9 h-9 sm:w-17 sm:h-17 border-2 rounded-full border-pink-logo bg-pink-logo transform ring-0 transition ease-in-out duration-200"></div>
				</div>
				<div className="logo-animate-gradient-purple-pink h-36 sm:h-72 w-9 sm:w-17 relative flex flex-col justify-between rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0"></div>
			</div>
			<div className="z-10 absolute">
				<div className="transform rotate-0 relative flex flex-col justify-between h-36 sm:h-72 rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0">
					<div className="single-button-logo-animate translate-x-0 pointer-events-none w-9 h-9 sm:w-17 sm:h-17 border-2 rounded-full border-blue-logo bg-blue-logo transform ring-0 transition ease-in-out duration-200"></div>
					<div className="single-button-logo-animate translate-x-0 pointer-events-none w-9 h-9 sm:w-17 sm:h-17 border-2 rounded-full border-orange-logo bg-orange-logo transform ring-0 transition ease-in-out duration-200"></div>
				</div>
			</div>
			<div className="z-10 absolute transform rotate-60 h-36 sm:h-72">
				<div className="logo-animate-transparent z-10 absolute h-36 sm:h-72 w-9 sm:w-17 flex flex-col justify-between">
					<div className="button-logo-animate translate-x-0 pointer-events-none w-9 h-9 sm:w-17 sm:h-17 border-2 rounded-full border-blue-logo-light bg-blue-logo-light transform ring-0 transition ease-in-out duration-200"></div>
					<div className="button-logo-animate translate-x-0 pointer-events-none w-9 h-9 sm:w-17 sm:h-17 border-2 rounded-full border-green-logo bg-green-logo transform ring-0 transition ease-in-out duration-200"></div>
				</div>
				<div className="logo-animate-gradient-green-blue h-36 sm:h-72 w-9 sm:w-17 relative flex flex-col justify-between rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0"></div>
			</div>
		</>
	)
}

export default IconLogoService;