const GradientSVG = () => {
	const idGradientCircleProgressBar = "id-gradient-circle-progress-bar";
	const gradientTransform = `rotate(98)`;
	return (
	  <svg style={{ height: 0 }}>
		<defs>
		  <linearGradient id={idGradientCircleProgressBar} gradientTransform={gradientTransform}>
			<stop offset="0%" stopColor="#b5aaff" />
			<stop offset="100%" stopColor="#6D5ED5" />
		  </linearGradient>
		</defs>
	  </svg>
	);
  }
  
  export default GradientSVG;
  