import { useState } from "react";

const FaqAccordion = () => {
	const [stateFaq, setStateFaq] = useState(null);

	const toggleStateFaq = (index) => {
		stateFaq === index ? setStateFaq(null) : setStateFaq(index);
	}

	const classIconFaq = (index) => {
		return (index === stateFaq) ? 'transform rotate-180 transition' : 'transform rotate-0 transition';
	}

	return (
		<>
			<dl className="space-y-6 sm:space-y-10 divide-y divide-gray-300">
				{FAQs.map((item, index) => (
					<div className="pt-6 sm:pt-10" key={index}>
						<dt className="text-base sm:text-lg md:text-2xl">
							<button
								onClick={() => toggleStateFaq(index)}
								type="button" className="text-left w-full flex justify-between items-start text-gray-800 focus:outline-none focus:ring-0 focus:border-transparent"
							>
								<span className="font-cera-pro-medium mr-1">{item.question}</span>
								<span className="ml-6 h-7 flex items-center">
									<svg className={`${classIconFaq(index)} w-6 h-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
									</svg>
								</span>
							</button>
						</dt>
						{stateFaq === index &&
							<dd className="mt-4 pr-12">
								<p className="text-sm sm:text-base md:text-lg text-gray-800">
									{item.answer}
								</p>
							</dd>
						}
					</div>
				))}
			</dl>
		</>
	)
}

const FAQs = [
	{
		question: 'Does xrecruiter have any ownership or equity stake in my new agency?',
		answer: 'Absolutely not. Put simply, we are a service provider to help recruiters start their own agency and assist with the day-to-day operations and management of their business. We charge a very small fee based on your revenue which pays your unlimited resources, back-office management, and ongoing running costs of your business. (Meaning, the the 75-90% Commissions you make is pure income in your pocket!)',
	},
	{
		question: 'Can I hire staff and scale up my agency?',
		answer: 'Yes you can. Once we’ve completed the handover of your new agency it’s time to set some goals. We walk through realistic timeframes on when and how to scale your agency, what type of cash position you should be in before hiring your first employee and much more. We can even attend interviews with you if you’re wanting a second opinion or perspective.'
	},
	{
		question: 'How much recruitment experience should I have before starting my own agency?',
		answer: 'As long as you can comfortably bill in excess of $300,000 per annum then it really doesn’t matter how much experience you have. The power of partnering with xrecruiter is having other recruitment agency owners who have walked your shoes and will be able to provide insights and advice that will reduce your learning curve, increase your confidence, and allow you to succeed far quicker and further then doing it by yourself.',
	},
	{
		question: 'How much money should I have before starting my own agency?',
		answer: 'The amazing part of xrecruiter is we cover all your operating expenses until you start making placements and clients have paid your placement fee – so you don’t need as much money saved as you might think. We do recommend having 2 months living expenses saved up just incase it takes a few months for your first invoice to get paid.'
	},
	{
		question: 'I have a non-compete clause – can I still start my own agency?',
		answer: 'If you think your employer will get nasty because you want to take the next step in your career – you’re probably working at the wrong place to begin with. If you have an existing network that follows you – no one can stop them choosing to work with you. (Even a noncompete with aggressive legal terminologies designed to scare you). We can help create a strategy around your noncompete or get our legal team to read the contract and see how legally binding it is.',
	},
	{
		question: 'Finance, budgeting, business, and operational stuff isn’t my strong suit. I just enjoy making placements and earning money – is this still for me?',
		answer: 'You are exactly who this is designed for. Independent, high performing recruiters who want to represent themselves and earn lots of money without having to invest any headspace or effort into operations, business set up, finance and all the typical complexities and headaches that may put people off working for themselves.',
	},
	{
		question: 'What happens If I do not want to use xrecruiter services anymore?',
		answer: 'You can discontinue the service at any time. XRecruiter is by far the best way to work for agencies under 10 staff for resources and management. However, once you grow past 10 fulltime recruiters then it’s probably time to chat about hiring your own management and accounts team.',
	},
	{
		question: 'How long does it take to start recruiting for my own recruitment agency?',
		answer: 'Depending on how quickly you want to get started- we could get you up and running within 2 weeks. However, to achieve a similar level of quality you see in the other brands we have built this is generally a 3-month process. Designing logo’s, choosing brand colours, agency style, developing websites and job boards can take time. We recommend starting the process immediately while you’re currently employed. Once handover is almost ready you can think about resigning!',
	},
	{
		question: 'I have some cool ideas I want to implement in my agency – can I do that?',
		answer: 'Of course you can – it’s your agency. Do whatever you think will set you apart, make you happy or create value for you, your clients or candidates. If you want to use xrecruiter as a soundboard to flesh out the details of your ideas, we are here to help make it happen!'
	}
];

export default FaqAccordion;