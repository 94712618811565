import Navbar from "../partials/Navbar";
import Footer from "../partials/Footer";

const BaseLayout = ({ isPage, children }) => {
  return (
    <>
      <Navbar />
      <main className="font-cera-pro-regular">{children}</main>
      <Footer isPage={isPage} />
    </>
  );
}

export default BaseLayout;
