import { useEffect, useState } from "react";

const OurStoryAccordion = () => {
	const accordionNames = {
		SUPPORT_AND_GUIDANCE: 'SUPPORT_AND_GUIDANCE',
		ACCESS_TO_RESOURCES: 'ACCESS_TO_RESOURCES',
		BUSINESS_MANAGEMENT: 'BUSINESS_MANAGEMENT',
	};
	
	const [stateAccordion, setStateAccordion] = useState(accordionNames.SUPPORT_AND_GUIDANCE);

	const toggleStateAccordion = (accordionName) => {
		(stateAccordion === accordionName) ? setStateAccordion('') : setStateAccordion(accordionName);
	}

	const classIconAccordion = (accordionName) => {
		return ( accordionName === stateAccordion ) ? 'transform rotate-180 transition' : 'transform rotate-0 transition';
	}

	useEffect(() => {
		const widthScreen = window.screen.width;
		if(widthScreen <= 640) {
			setStateAccordion('');
		}
	}, [])

	return (
		<>
			<div className="max-w-3xl mx-auto md:flex md:justify-between">
				<div className="w-full">
					<button
						onClick={() => toggleStateAccordion(accordionNames.SUPPORT_AND_GUIDANCE)}
						className="w-full inline-flex justify-between md:mb-5 mb-7 md:justify-center items-center text-center text-base sm:text-lg font-semibold text-gray-800 focus:outline-none focus:ring-0 focus:border-transparent"
					>
						Support and Guidance
						<svg xmlns="http://www.w3.org/2000/svg" className={`${classIconAccordion(accordionNames.SUPPORT_AND_GUIDANCE)} h-5 sm:h-6 w-5 sm:w-6 ml-2`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
							<path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
						</svg>
					</button>
					{stateAccordion === accordionNames.SUPPORT_AND_GUIDANCE &&
						<div className="mb-7 md:hidden">
							<p className="text-left md:text-center text-sm sm:text-base font-normal text-gray-800">
								Most recruiters possess all the exact same qualities of successful businesses owners.
								One of the main contributing factors that stops a successful recruiter representing themselves
								is the lack of confidence and clarity on where to start. Sometimes all they need is someone to talk to.
								XRecruiter solves this by working with our partners to create a game plan, get clear on how to execute,
								and support them at every step.
							</p>
						</div>
					}
				</div>
				<div className="w-full">
					<button
						onClick={() => toggleStateAccordion(accordionNames.ACCESS_TO_RESOURCES)}
						className="w-full inline-flex justify-between md:mb-5 mb-7 md:justify-center items-center text-center text-base sm:text-lg font-semibold text-gray-800 focus:outline-none focus:ring-0 focus:border-transparent"
					>
						Access to Resources
						<svg xmlns="http://www.w3.org/2000/svg" className={`${classIconAccordion(accordionNames.ACCESS_TO_RESOURCES)} h-5 sm:h-6 w-5 sm:w-6 ml-2`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
							<path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
						</svg>
					</button>
					{stateAccordion === accordionNames.ACCESS_TO_RESOURCES &&
						<div className="mb-7 md:hidden">
							<p className="text-left md:text-center text-sm sm:text-base font-normal text-gray-800">
								The second challenge is access to resources. Most suppliers demand long 12–24-month contracts,
								minimum spend commitments and costly subscription services. This makes accessing products
								such as CRM’s, Website hosting, Company Servers, Seek Ads, LinkedIn Recruiter Licenses,
								and general resources difficult. XRecruiter solves this by providing our partner network
								with their own pool of resources - without any contracts, commitments, or minimum spend.
								In fact, you only pay for your resources once you’ve started making placements and the
								client has paid your placement fee. Removing all operational expenses and risk until you’re on your feet.
							</p>
						</div>
					}
				</div>
				<div className="w-full">
					<button
						onClick={() => toggleStateAccordion(accordionNames.BUSINESS_MANAGEMENT)}
						className="w-full inline-flex justify-between md:mb-5 mb-7 md:justify-center items-center text-center text-base sm:text-lg font-semibold text-gray-800 focus:outline-none focus:ring-0 focus:border-transparent"
					>
						Business Management
						<svg xmlns="http://www.w3.org/2000/svg" className={`${classIconAccordion(accordionNames.BUSINESS_MANAGEMENT)} h-5 sm:h-6 w-5 sm:w-6 ml-2`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
							<path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
						</svg>
					</button>
					{stateAccordion === accordionNames.BUSINESS_MANAGEMENT &&
						<div className="mb-7 md:hidden">
							<p className="text-left md:text-center text-sm sm:text-base font-normal text-gray-800">
								Finally, managing the internal operations such as invoicing placements, cashflow, admin,
								accounts and marketing can cause major distractions to talented recruiters. This causes
								headaches which impact their ability to bring in revenue, build relationships, and keep
								the lights on. XRecruiter solves this by providing a full white label solution to manage
								their entire back office so they can focus on what they do best – recruitment. In short,
								create your own brand and increase your income without adding any more responsibilities
								than you currently have.
							</p>
						</div>
					}
				</div>
			</div>
			<div className="max-w-4xl mx-auto md:block hidden">
				{stateAccordion === accordionNames.SUPPORT_AND_GUIDANCE &&
					<p className="text-left md:text-center text-sm sm:text-base font-normal text-gray-800">
						Most recruiters possess all the exact same qualities of successful businesses owners.
						One of the main contributing factors that stops a successful recruiter representing themselves
						is the lack of confidence and clarity on where to start. Sometimes all they need is someone to talk to.
						XRecruiter solves this by working with our partners to create a game plan, get clear on how to execute,
						and support them at every step.
					</p>
				}
				{stateAccordion === accordionNames.ACCESS_TO_RESOURCES &&
					<p className="text-left md:text-center text-sm sm:text-base font-normal text-gray-800">
						The second challenge is access to resources. Most suppliers demand long 12–24-month contracts,
						minimum spend commitments and costly subscription services. This makes accessing products
						such as CRM’s, Website hosting, Company Servers, Seek Ads, LinkedIn Recruiter Licenses,
						and general resources difficult. XRecruiter solves this by providing our partner network
						with their own pool of resources - without any contracts, commitments, or minimum spend.
						In fact, you only pay for your resources once you’ve started making placements and the
						client has paid your placement fee. Removing all operational expenses and risk until you’re on your feet.
					</p>
				}
				{stateAccordion === accordionNames.BUSINESS_MANAGEMENT &&
					<p className="text-left md:text-center text-sm sm:text-base font-normal text-gray-800">
						Finally, managing the internal operations such as invoicing placements, cashflow, admin,
						accounts and marketing can cause major distractions to talented recruiters. This causes
						headaches which impact their ability to bring in revenue, build relationships, and keep
						the lights on. XRecruiter solves this by providing a full white label solution to manage
						their entire back office so they can focus on what they do best – recruitment. In short,
						create your own brand and increase your income without adding any more responsibilities
						than you currently have.
					</p>
				}
			</div>
		</>
	)
}

export default OurStoryAccordion;