import Helmet from 'react-helmet'
import { useEffect, useRef } from "react";
import PageNameConst from "../Const/PageNameConst.js";

import BaseLayout from "../layouts/BaseLayout";
import WhiteButton from "../components/UI/Button/WhiteButton.js";
import SupportedSlider from "../components/Slider/SupportedSlider.jsx";
import PeolpeOurStorySlider from "../components/Slider/PeolpeOurStorySlider.js";
import OurStoryAccordion from "../components/Accordion/OurStoryAccordion.js";
import FaqAccordion from "../components/Accordion/FaqAccordion";

// images
import ImgPartnerHalisoft from "../assets/images/partner-halisoft.jpg";
import ImgPartnerIndustryTalent from "../assets/images/partner-industry-talent.jpg"
import ImgHowDoesWork from "../assets/images/how_does_work.png";
import ImgBlake from "../assets/images/people_1.jpg";
import ImgDeclan from "../assets/images/people_2.png";
import MessageIcon from "../assets/icons/message.svg";
import CallIcon from "../assets/icons/call.svg";

// videos
import VideoHowDoesWork from "../assets/videos/introduction.m4v";
import ImgLogo from "../assets/images/logo.png";
import PartnersSaySlider from '../components/Slider/PartnersSaySlider.jsx';
import PartnersSayWithHover from '../components/VideoPlayer/PartnersSayWithHover.jsx';
import VideoPartnerHalisoft from "../assets/videos/testimonial-halisoft.mov";
import VideoPartnerIndustryTalent from "../assets/videos/testimonial-industry-talent.mp4";

const About = () => {
  const HowDoesWorkSection = useRef();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>About Us XRecruiter | Recruitment Consultant</title>
        <meta name='description' content='We are an innovative recruitment agency specializing in smart recruiting solutions to deliver market advantage from conception to end & help a business scale.' />
        <meta name='keywords' content='recruitment consultant, innovative recruitment agency' />
        <link rel='canonical' href='https://xrecruiter.io/about' />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Does xrecruiter have any ownership or equity stake in my new agency?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Absolutely not. Put simply, we are a service provider to help recruiters start their own agency and assist with the day-to-day operations and management of their business. We charge a very small fee based on your revenue which pays your unlimited resources, back-office management, and ongoing running costs of your business. (Meaning, the the 75-90% Commissions you make is pure income in your pocket!)"
              }
            },{
              "@type": "Question",
              "name": "Can I hire staff and scale up my agency?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes you can. Once we’ve completed the handover of your new agency it’s time to set some goals. We walk through realistic timeframes on when and how to scale your agency, what type of cash position you should be in before hiring your first employee and much more. We can even attend interviews with you if you’re wanting a second opinion or perspective."
              }
            },{
              "@type": "Question",
              "name": "How much recruitment experience should I have before starting my own agency?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "As long as you can comfortably bill in excess of $300,000 per annum then it really doesn’t matter how much experience you have. The power of partnering with xrecruiter is having other recruitment agency owners who have walked your shoes and will be able to provide insights and advice that will reduce your learning curve, increase your confidence, and allow you to succeed far quicker and further then doing it by yourself."
              }
            },{
              "@type": "Question",
              "name": "How much money should I have before starting my own agency?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The amazing part of xrecruiter is we cover all your operating expenses until you start making placements and clients have paid your placement fee – so you don’t need as much money saved as you might think. We do recommend having 2 months living expenses saved up just incase it takes a few months for your first invoice to get paid."
              }
            },{
              "@type": "Question",
              "name": "I have a non-compete clause – can I still start my own agency?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "If you think your employer will get nasty because you want to take the next step in your career – you’re probably working at the wrong place to begin with. If you have an existing network that follows you – no one can stop them choosing to work with you. (Even a noncompete with aggressive legal terminologies designed to scare you). We can help create a strategy around your noncompete or get our legal team to read the contract and see how legally binding it is."
              }
            },{
              "@type": "Question",
              "name": "Finance, budgeting, business, and operational stuff isn’t my strong suit. I just enjoy making placements and earning money – is this still for me?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "You are exactly who this is designed for. Independent, high performing recruiters who want to represent themselves and earn lots of money without having to invest any headspace or effort into operations, business set up, finance and all the typical complexities and headaches that may put people off working for themselves."
              }
            },{
              "@type": "Question",
              "name": "What happens If I do not want to use xrecruiter services anymore?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "You can discontinue the service at any time. XRecruiter is by far the best way to work for agencies under 10 staff for resources and management. However, once you grow past 10 fulltime recruiters then it’s probably time to chat about hiring your own management and accounts team."
              }
            },{
              "@type": "Question",
              "name": "How long does it take to start recruiting for my own recruitment agency?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Depending on how quickly you want to get started- we could get you up and running within 2 weeks. However, to achieve a similar level of quality you see in the other brands we have built this is generally a 3-month process. Designing logo’s, choosing brand colours, agency style, developing websites and job boards can take time. We recommend starting the process immediately while you’re currently employed. Once handover is almost ready you can think about resigning!"
              }
            },{
              "@type": "Question",
              "name": "I have some cool ideas I want to implement in my agency – can I do that?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Of course you can – it’s your agency. Do whatever you think will set you apart, make you happy or create value for you, your clients or candidates. If you want to use xrecruiter as a soundboard to flesh out the details of your ideas, we are here to help make it happen!"
              }
            }]
          }`}
        </script>
      </Helmet>
      <BaseLayout isPage={PageNameConst.ABOUT_US}>
        {/* hero section */}
        <section className="bg-gradient-to-tr from-pink-30 via-green-30 to-blue-30 h-screen sm:h-1024 md:h-1366 xl:h-screen">
          <div className="mx-auto max-w-7xl flex items-center h-full">
            <div className="text-center max-w-4xl mx-auto px-0 mt-16">
              <h1 className="tagline-animate leading-10 lg:leading-72 text-[19px] xs:text-2xl sm:text-4xl md:text-5xl lg:text-6xl text-center font-cera-pro-bold sm:font-cera-pro-medium font-bold sm:font-medium text-gray-900">
                The Future of Recruitment is <br />
                Becoming Decentralised, <br />
                Independent and Co-Op
              </h1>
              <button onClick={() => HowDoesWorkSection.current.scrollIntoView({ behavior: 'smooth' })} type="button" className="font-cera-pro-regular sm:font-cera-pro-medium mt-8 sm:mt-16 xl:mt-20 uppercase font-normal sm:font-medium text-sm xs:text-lg text-gray-900 hover:text-gray-900 inline-flex items-center">
                Scroll Down
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 xs:h-5 sm:h-6 h-4 xs:w-5 sm:w-6 ml-2 button-scroll-animate" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
                </svg>
              </button>
            </div>
          </div>
        </section>

        {/* how does work section */}
        <section ref={HowDoesWorkSection} className="bg-gradient-to-tr from-pink-30 via-green-30 to-blue-30">
          <div className="mx-auto max-w-6xl py-12 sm:py-30">
            <div className="text-center">
              <h2 className="mx-auto text-[19px] xs:text-2xl sm:text-4xl md:text-6xl leading-10 md:leading-72 sm:font-cera-pro-medium sm:font-medium font-cera-pro-bold font-bold text-gray-900">
                How Does XRecruiter Work
              </h2>
            </div>
            <div className="px-4 xs:px-6 sm:px-12 md:px-14 lg:px-20 xl:px-4">
              <div className="w-full mx-auto my-4 sm:my-12">
                <video controls className="w-full" poster={ImgHowDoesWork}>
                  <source src={VideoHowDoesWork} type="video/mp4"></source>
                </video>
              </div>
              <div>
                <div className="grid md:grid-cols-2 md:gap-2 lg:gap-4">
                  <div className="md:border-0 md:pb-0 pb-8 border-b border-gray-400">
                    <p className="text-base sm:text-2xl sm:font-cera-pro-medium sm:font-medium font-cera-pro-bold font-bold text-gray-900 mb-2">
                      Have some questions?
                    </p>
                    <button type="button" onClick={() => window.open("https://calendly.com/d/ykj-jtv-x3m/introductory-chat")} className="focus:outline-none focus:ring-0 text-left flex items-center sm:justify-start sm:underline text-base lg:text-lg font-normal text-gray-900">
                      15-minutes casual introductory chat
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1 md:ml-2 transform rotate-45" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 10l7-7m0 0l7 7m-7-7v18" />
                      </svg>
                    </button>
                  </div>
                  <div className="md:border-0 md:pt-0 pt-8">
                    <p className="text-base sm:text-2xl sm:font-cera-pro-medium sm:font-medium font-cera-pro-bold font-bold text-gray-900 mb-2">
                      Ready to start your own recruitment agency?
                    </p>
                    <button type="button" onClick={() => window.open("https://calendly.com/d/yw8-7t6-zmx/discovery-call")} className="focus:outline-none focus:ring-0 text-left flex items-center sm:justify-start sm:underline text-base lg:text-lg font-normal text-gray-900">
                      45 minutes discovery call to talk strategy
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1 md:ml-2 transform rotate-45" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 10l7-7m0 0l7 7m-7-7v18" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* supported section */}
        <section className="bg-gradient-to-tr from-pink-30 via-green-30 to-blue-30">
          <div className="mx-auto py-12 sm:py-28 xl:py-30">
            <div className="text-center mb-4 sm:mb-24 md:mb-28 xl:mb-32 px-4">
              <h2 className="mx-auto text-[19px] xs:text-2xl sm:text-4xl md:text-6xl leading-10 md:leading-72 sm:font-cera-pro-medium sm:font-medium font-cera-pro-bold font-bold text-gray-900">
                Other Brands Built and <br className="hidden sm:block" /> Supported by XRecruiter
              </h2>
            </div>
            <div className="w-full mx-auto xl:mb-28">
              <SupportedSlider />
            </div>
          </div>
        </section>

        {/* our story section */}
        <section className="bg-gradient-to-tr from-pink-30 via-green-30 to-blue-30">
          <div className="py-12 sm:py-28">
            <div className="px-4 xs:px-5 sm:px-12 md:px-14 lg:px-20 xl:px-4 mx-auto max-w-6xl mb-5 sm:mb-12">
              <div className="flex text-center mb-4 sm:mb-14 md:mb-16 px-4 xl:mt-1.5">
                <h2 className="mx-auto text-xl xs:text-3xl sm:text-4xl md:text-6xl leading-10 md:leading-72 sm:font-cera-pro-medium sm:font-medium font-cera-pro-bold font-bold text-gray-900">
                  Our Story
                </h2>
              </div>
              <div className="border-b border-gray-300 pb-8 sm:pb-12 mb-8 sm:mb-12">
                <div className="grid sm:grid-cols-2 gap-6 sm:gap-12 xl:gap-20">
                  <div >
                    <p className="text-center md:text-justify xl:text-left text-base sm:text-lg font-normal text-gray-900">
                      Blake and Declan both run their own successful recruitment agencies to this day.
                      One of the reasons they’ve achieved success is from sharing their experiences, challenges,
                      and achievements in order to better refine themselves and their own recruitment agencies.
                    </p>
                  </div>
                  <div >
                    <p className="text-center md:text-justify xl:text-left text-base sm:text-lg font-normal text-gray-900">
                      It’s no secret successful recruiters are hardworking, talented, and entrepreneurial
                      by nature - creating value from who they are and not where they work.
                      So what is stopping successful recruiters branch out on their own and represent themselves?
                    </p>
                  </div>
                </div>
              </div>
              <div className="max-w-4xl mx-auto mb-8 sm:mb-12">
                <p className="text-center text-base sm:text-lg font-normal text-gray-900">
                  <span className="font-cera-pro-bold">
                    XRecruiter is pioneering an industry first approach to the future of recruitment.&nbsp;
                  </span> <br className="hidden xl:inline-block" />
                  XRecruiter was born from recognising there’s 3 core elements to why successful recruiters
                  don’t take the next step in their career and represent themselves and that is:
                </p>
              </div>
              <div className="-mx-0 md:-mx-2 lg:-mx-0">
                <OurStoryAccordion />
              </div>
            </div>
            <div className="w-full">
              {/* tablet-dekstop screen */}
              <div className="sm:block hidden sm:px-12 md:px-14 lg:px-20 xl:px-4 w-full md:max-w-5xl mx-auto">
                <div className="flex sm:pb-0 pb-2 sm:overflow-hidden overflow-scroll scrollbar-hide sm:grid sm:grid-cols-2 gap-0 sm:gap-6 lg:gap-12">
                  <div className="sm:ml-0 ml-6">
                    <p className="text-justify text-lg sm:text-3xl font-normal text-gray-900">
                      Who’s Blake?
                    </p>
                    <div className="my-6 sm:w-full w-64 h-72 aspect-w-1 aspect-h-1 overflow-hidden lg:aspect-none">
                      <img src={ImgBlake} alt="img" className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                    </div>
                    <p className="text-justify text-base sm:text-lg font-normal text-gray-900">
                      Follow Blake on
                      <span className="ml-1 cursor-pointer focus:outline-none focus:ring-0 inline-flex underline" onClick={() => window.open("https://www.linkedin.com/in/blakethompson92/")}>
                        LinkedIn.
                      </span>
                    </p>
                  </div>
                  <div className="sm:ml-0 ml-6 sm:mr-0 mr-6">
                    <p className="text-justify text-lg sm:text-3xl font-normal text-gray-900">
                      Who’s Declan?
                    </p>
                    <div className="my-6 sm:w-full w-64 h-72 aspect-w-1 aspect-h-1 overflow-hidden lg:aspect-none">
                      <img src={ImgDeclan} alt="img" className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                    </div>
                    <p className="text-justify text-base sm:text-lg font-normal text-gray-900">
                      Follow Declan on
                      <span className="ml-1 cursor-pointer focus:outline-none focus:ring-0 inline-flex underline" onClick={() => window.open("https://www.linkedin.com/in/declan-kluver/")}>
                        LinkedIn.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full sm:hidden block">
                <PeolpeOurStorySlider />
              </div>
            </div>
          </div>
        </section>

        {/* what do your partners section */}
        <section className="overflow-hidden bg-gradient-to-tr from-pink-30 via-green-30 to-blue-30">
          <div className="mx-auto py-12 sm:py-[120px]">
            <div className="text-center">
              <h2 className="mx-auto text-[19px] xs:text-2xl sm:text-4xl md:text-[56px] !leading-9 sm:!leading-10 md:!leading-72 sm:font-cera-pro-medium sm:font-medium font-cera-pro-bold font-bold text-gray-900">
                What Do Our Partners Say?
              </h2>
            </div>

            {/* <PartnersSaySlider/> */}
            <div className='flex flex-col xl:flex-row items-center space-y-6 md:space-y-16 xl:space-y-0 space-x-0 xl:space-x-16 relative mx-auto w-fit mt-4 sm:mt-16 xl:mt-18'>
              <PartnersSayWithHover
                videoSrc={VideoPartnerIndustryTalent}
                placeholderSrc={ImgPartnerIndustryTalent}
              />
              <PartnersSayWithHover
                videoSrc={VideoPartnerHalisoft}
                placeholderSrc={ImgPartnerHalisoft}
              />
            </div>
          </div>
        </section>

        {/* chat to us today section */}
        <section className="bg-gradient-to-tr from-pink-30 via-green-30 to-blue-30">
          <div className="py-12 sm:py-30 xl:py-32 mx-auto max-w-6xl">
            <div className="flex text-center mb-4 sm:mb-20">
              <h2 className="mx-auto text-[19px] xs:text-2xl sm:text-4xl md:text-6xl leading-10 md:leading-72 sm:font-cera-pro-medium sm:font-medium font-cera-pro-bold font-bold text-gray-900">
                Chat to us today and <br /> discover what’s possible
              </h2>
            </div>
            <div className="px-4 xs:px-6 sm:px-12 md:px-14 lg:px-16 xl:px-4">
              <div className="grid sm:grid-cols-2 gap-6 sm:gap-8 lg:gap-12">
                <div className="bg-white p-8 xs:p-10 xl:px-14 xl:py-15">
                  <div className="mb-7 xl:mb-8 flex">
                    <img src={MessageIcon} alt="" className="sm:w-auto w-6 mr-6" />
                    <p className="text-left text-base xs:text-lg lg:text-2xl font-cera-pro-bold text-gray-900">
                      I have some questions
                    </p>
                  </div>
                  <div className="cursor-pointer focus:outline-none focus:ring-0" onClick={() => window.open("https://calendly.com/d/ykj-jtv-x3m/introductory-chat")}>
                    <WhiteButton title="15 minutes - casual chat" customClass="sm:w-auto w-full text-base font-cera-pro-medium" />
                  </div>
                </div>
                <div className="bg-white p-8 xs:p-10 xl:px-14 xl:py-15">
                  <div className="mb-7 xl:mb-8 flex">
                    <img src={CallIcon} alt="" className="sm:w-auto w-6 mr-6" />
                    <p className="text-left text-base xs:text-lg lg:text-2xl font-cera-pro-bold text-gray-900">
                      I'm ready Let's do it!
                    </p>
                  </div>
                  <div className="cursor-pointer focus:outline-none focus:ring-0" onClick={() => window.open("https://calendly.com/d/yw8-7t6-zmx/discovery-call")} >
                    <WhiteButton title="45 minutes - strategy call" customClass="sm:w-auto w-full text-base font-cera-pro-medium" />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 mt-9 sm:mt-20 xl:mt-20">
                <div className="mx-auto">
                  <div className="flex justify-center">
                    <img src={ImgLogo} alt="" className="h-9 sm:h-16" />
                  </div>
                  <div className="max-w-lg text-center mt-6">
                    <p className="text-lg font-cera-pro-bold text-gray-900">
                      Are you a <br className="sm:hidden" /> recruitment agency owner?
                    </p>
                    <p className="text-lg text-gray-900 mt-5 mb-8 xl:my-2">
                      XRecruiter can help you too. Book a call to learn how we can improve your brand,
                      reduce your overheads, automate internal process, remove stress and increase profitability.
                    </p>
                    <div className="cursor-pointer focus:outline-none focus:ring-0" onClick={() => window.open("https://calendly.com/xrecruiter-blake/30-minute-agency-strategy-call")}>
                      <WhiteButton title="Book a 30 minutes  strategy call here" customClass="text-base font-cera-pro-medium" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* faqs section */}
        <section className="bg-gradient-to-tr from-pink-30 via-green-30 to-blue-30">
          <div className="mx-auto max-w-5xl px-6 sm:px-12 md:px-14 lg:px-16 xl:px-4 py-12 sm:py-32 xl:py-32">
            <div className="text-center">
              <h2 className="mx-auto text-xl xs:text-3xl sm:text-4xl md:text-5xl xl:text-6xl leading-10 tracking-wide sm:font-semibold font-bold text-gray-900">
                FAQ
              </h2>
            </div>
            <div className="w-full mx-auto mt-8 sm:mt-20">
              <FaqAccordion />
            </div>
          </div>
        </section>
      </BaseLayout>
    </>
  );
}

export default About;
